import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { usePostApi } from './useDataApiV2';
import MasterPage from './MasterPage';
import '../App.css';
import { ModalMessageBox, ModalMessageResponseFlags } from './SamModalV2';
import SamForm from '../libs/forms/SamFormV5';
import AddressEditor, { AddressSchemaType } from '../libs/forms/AddressEditor';

import { DivisionEnum, FormFieldRecord, FormFieldType } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import api from '../api-url';
import { useGlobalContext } from './SamState';
import FormMgr from './forms/FormMgr';

const FormContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 600px;
`
const PdfContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
`
interface GetCatalogInputs {
    license: string;
    comment: string;
    division: DivisionEnum;
    reason: string;
}
const Catalog: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [fetchingCatalog, setFetchingCatalog] = React.useState<GetCatalogInputs>();
    const [catalogOpen, setCatalogOpen] = React.useState(false);

    const { post } = usePostApi();
    const navigate = useNavigate();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const [showCatalog, setShowCatalog] = React.useState(false);

    const postSucceeded = () => {
        if (!catalogOpen) {
            window.open(app.catalogUrl);
            setCatalogOpen(true);
        }
    }
    const postFailed = () => {
        setIsModalOpen(true);
    }
    React.useEffect(() => {
        if (fetchingCatalog) {
            post(api.contact, fetchingCatalog, postSucceeded, postFailed);
            setFetchingCatalog(undefined);
        }
    }, [fetchingCatalog]);
    const handleSubmit = (inputs: Record<string, any> | null) => {
        if (inputs) {
            if (!forms.validateForm("catalog") || !forms.validateForm("catalogSupp")) {
                return;
            }
            const values = forms.getFormValues("catalog") as GetCatalogInputs;
            values.license = inputs.license;
            values.comment = inputs.comment;
            values.division = app.division;
            values.reason = "Customer downloaded a catalog";
            setFetchingCatalog(values);
        } else {
            navigate('/');
        }
    }
    // if condition is set, that condition must be set to true via setCondition or field will not be rendered
    const formData: FormFieldRecord[] = [
        { name: "license", label: "Resale ID number", validator: { maxLength: 100, required: true } },
        { name: "comment", label: "Comment (250 character maximum)", type: FormFieldType.multiLine, validator: { maxLength: 250 } },
    ];
    return (
        <MasterPage>
            <h1>View Our Catalog</h1>
            <FormContainer>
                <AddressEditor id="catalog" values={null} excludedFields={["storefront", "res_del", "is_default"]} schemaType={AddressSchemaType.wholesale}
                    companyRequired={true} />
                <SamForm id="catalogSupp" fields={formData} submitButtons={[{ id: "submit", caption: "Download catalog", icon: "fas fa-download" }]}
                    handleSubmit={handleSubmit} />
            </FormContainer>
            {isModalOpen && <ModalMessageBox caption="We're so sorry. We are having problems processing your request right now. Please try again later or call us at 800-627-5840."
                responseFlags={ModalMessageResponseFlags.ok}
                onSubmit={() => setIsModalOpen(false)} />
            }
        </MasterPage>
    );
}
export default Catalog;
