import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import app from '../appData';

const StyledTransparentContainer = styled.div<{ top: number; left: number; width: number; height: number }>`
    position: fixed;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    z-index: 1000;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: transparent;
`
const StyledPopup = styled.div<{ top: number; left: number; width: number }>`
    position: fixed;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    z-index: 1100;
    width: ${props => props.width}px;
`
export interface PopupProps {
    Popup: React.FC;
    top: number;
    left: number;
    width: number;
    height?: number
}
const usePopups = () => {
    const [popup, setPopup] = React.useState<PopupProps | null>(null);
    const [height, setHeight] = React.useState<number>(0);

    const popupId = "popupBox";
    const borderWidth = 12;         // transparent border around area cursor is allowed to be in
    const topBarHeight = 40;      // bar above popup where cursor is assumed to be when popup is shown

    React.useEffect(() => {
        const popupBox = document.getElementById(popupId);
        if (popupBox && !height) {
            setHeight(popupBox.getBoundingClientRect().height + topBarHeight + 2 * borderWidth);        // full height of transparent box
        }
    });
    const invokePopup = (props: PopupProps) => {
        setPopup(props);
    }
    const closePopup = () => {
        setPopup(null);
    }

    const renderPopup = () => {
        return (
            popup &&
            <React.Fragment>
                <StyledTransparentContainer top={popup.top - topBarHeight - borderWidth} left={popup.left - borderWidth}
                    width={popup.width + 2 * borderWidth} height={height} onMouseLeave={closePopup} />
                <StyledPopup id={popupId} top={popup.top - borderWidth} left={popup.left - borderWidth} width={popup.width}>
                    <popup.Popup />
                </StyledPopup>
            </React.Fragment>
        );
    }

    return {
        renderPopup,
        invokePopup,
        closePopup
    }
}
export default usePopups;


