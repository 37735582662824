import React from 'react';
import styled from 'styled-components';

const MasterContainer = styled.div<{ width: number; height: number }>`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    overflow: hidden; 
    padding: 4px;
`
const MainImage = styled.img<{ width: number; height: number }>`
    max-height: ${props => props.height}px;
    max-width: ${props => props.width}px;
    width: auto;
    height: auto;
`
const MagImage = styled.img<{ width: number; height: number; left: number; top: number }>`
    position: absolute;
    left: ${props => props.left}px;
    top: ${props => props.top}px;
    max-height: ${props => props.height}px;
    max-width: ${props => props.width}px;
    width: auto;
    height: auto;
    opacity: 0;
    transition: opacity .5s;
`
interface LocationRecord {
    x: number;
    y: number;
}
interface MagnifierProps {
    width: number;
    height: number;
    mainImgSrc: string;
    magImgSrc?: string;     // omit to suppress magnify on hover
}
const Magnifier: React.FC<MagnifierProps> = (props) => {
    const masterRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const mainImageRef = React.useRef<HTMLImageElement>() as React.MutableRefObject<HTMLImageElement>;
    const magImageRef = React.useRef<HTMLImageElement>() as React.MutableRefObject<HTMLImageElement>;

    const [masterLocation, setMasterLocation] = React.useState<LocationRecord>();
    const [mainImageLocation, setMainImageLocation] = React.useState<LocationRecord>({ x: 0, y: 0 });
    const [magLocation, setMagLocation] = React.useState<LocationRecord>({ x: 0, y: 0 });
    const [isMagShown, setIsMagShown] = React.useState<boolean>(false);

    React.useEffect(() => {
        let rect = masterRef.current!.getBoundingClientRect();
        setMasterLocation({ x: rect.x, y: rect.y });
    }, []);

    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement;
        const rect = target.getBoundingClientRect();
        setMainImageLocation({ x: rect.x - masterLocation!.x, y: rect.y - masterLocation!.y });
    }

    const handleMouseEnter = () => {
        if (props.magImgSrc) {
            magImageRef.current!.style.opacity = "1";
            setIsMagShown(true);
        }
    }
    const handleMouseLeave = () => {
        if (props.magImgSrc) {
            magImageRef.current!.style.opacity = "0";
            setIsMagShown(false);
        }
    }
    const masterMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const masterX = e.clientX - masterLocation!.x;
        const masterY = e.clientY - masterLocation!.y;
        setMagLocation({ x: mainImageLocation!.x * 2 - masterX, y: 2 * mainImageLocation!.y - masterY });
    }

    return (
        <MasterContainer ref={masterRef} width={props.width} height={props.height} onMouseMove={masterMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.magImgSrc && <MagImage ref={magImageRef} src={props.magImgSrc} width={props.width * 2} height={props.height * 2} left={magLocation!.x} top={magLocation!.y} />}
            {!isMagShown && <MainImage ref={mainImageRef} src={props.mainImgSrc} width={props.width} height={props.height} onLoad={imageLoaded} />}
        </MasterContainer>
    )
}
export default Magnifier;
