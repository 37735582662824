import path from "path-browserify";
import { insert } from "./libSupport";

import { cdnDomain, GraphicDimensionType, GraphicFloatType, ImageFileOptions, ImageRecord, VideoStreamSource } from "../interfaces/lib-api-interfaces";

import app from '../appData';

export const videoHeightFactor = .56;

// use this if image could be video or blob; (use formatImageUrl if filename only)
export const formatImageOrVideoSrcWithFile = (image: ImageRecord, fileOptions?: ImageFileOptions): string => {
    if (image.stream_thumb_src) {
        return image.stream_thumb_src;
    }
    return (image.file ? URL.createObjectURL(image.file) : formatImageUrl(image.filename!, fileOptions));
}

// if this is on nginx server we just need https://domain or  https://CDN-DOMAIN
// if this is on local dev server, domain depends on whether this is dashboard and whether image is on cdn
const formatDomainPart = (isCdn?: boolean) => {
    const removeTld = (domain: string): string => {
        return domain.substring(0, domain.indexOf('.'));
    }
    if (window.location.hostname !== "localhost") {
        // on remote server
        return "https://" + (isCdn ? cdnDomain : app.targetDomain);
    } else {
        if (app.isDashboard) {
            let result = "http://localhost:4000/";
            if (isCdn) {
                result += removeTld(cdnDomain) + "/cdn/";
            } else {
                result += removeTld(app.targetDomain) + "/public/";
            }
            return result;
        } else {
            return process.env.PUBLIC_URL + "/";
        }
    }
}
// if size is passed it is inserted with underscore before file extension
// e.g.: insert("hello.jpg", "f") -> "hello_f.jpg"
// dashboard uses targetDomain as root, otherwise use app.productGraphicsBaseUrl (PUBLIC folder if running locally)
// app.targetDomain = "laurainbocrystals.com" or "sam.bond" etc. (if this is dashboard the targetDomain is to the production site)
// if graphics are on cdn assume that they will be read off remote server even if this is dev server (app.allGraphicsLocal=true to override)
export const formatImageUrl = (filename: string, fileOptions?: ImageFileOptions) => {
    if (!filename) {
        return '';
    }
    // domainPart takes us through .com or .lol etc.
    const domainPart = formatDomainPart(fileOptions?.isCdn);
    const subFolder = "graphics/" + (fileOptions?.graphicsSubfolder ? (fileOptions.graphicsSubfolder) : '');
    const filenamePart = (fileOptions?.sizeDesignator && filename.length > 4) ? insert(filename, filename.length - 4, "_" + fileOptions.sizeDesignator) : filename;
    const url = domainPart + "/" + path.join(subFolder, filenamePart);
    //  console.log({ isDev, domainPart, subFolder, graphicsFolder, cdnTargetDomain, url });
    return url.replace("//graphics", "/graphics");
}
export const formatYoutubeThumbUrl = (id: string): string => {
    return "https://img.youtube.com/vi/" + id + "/0.jpg";
}
export const formatVimeoApiUrl = (id: string): string => {
    return "http://vimeo.com/api/v2/video/" + id + ".json";
}
export const formatVideoIframeSrc = (video: ImageRecord): string => {
    if (video.stream_source === VideoStreamSource.vimeo) {
        return "https://player.vimeo.com/video/" + video.stream_id;
    } else {
        return "https://www.youtube.com/embed/" + video.stream_id;
    }
}
// remove domain info and sizing info; return filename itself
export const deformatImageUrl = (url: string, hasSizeDescriptor: boolean): string => {
    const nameStartPosn = url.lastIndexOf('/') + 1;
    let filename = url.substring(nameStartPosn);
    return filename.replace("_f.", '.').replace("_m.", '.');
}

// use following when loading html docs that include images or videos
// fileOptions is used for graphicsSubfolder and sizeDesignator
// domain id under cdn folder is taken from app.targetDomain (see formatImageUrl for all rules)
// if image is external and should not be formatted, attribute "isExternal" must be set to true
export const activateImagesAndVideos = (root: HTMLElement, fileOptions: ImageFileOptions, skipVideos: boolean) => {
    const figures = root.getElementsByTagName('figure');
  //  console.log("activateImages: figures:", figures)
    for (let i = 0; i < figures.length; i++) {
        const image = createImageFromElement(figures[i], fileOptions)!;
        const img = figures[i].getElementsByTagName("img")[0];
    //    console.log("activateImages...:", image)
        if (image.stream_id) {
            // this is a video
            if (!skipVideos) {
                const iframe = createIframeFromImage(image);
                figures[i].replaceChild(iframe, img)
            }
        } else {
            if (img.getAttribute("isExternal") !== "true") {
                img.setAttribute("src", formatImageUrl(image.filename!, fileOptions));
            }
        }
    }
}
// image must have valid video fields
export const createIframeFromImage = (image: ImageRecord): HTMLElement => {
    // create iframe element
    const iframe = document.createElement('iframe');
    const src = formatVideoIframeSrc(image);
    if (image.stream_source === VideoStreamSource.vimeo) {
        iframe.setAttribute("allow", "autoplay; fullscreen; picture-in-picture");
    } else {
        iframe.setAttribute("allow", "web-share");
    }
    iframe.setAttribute("allowfullscreen", '');
    iframe.setAttribute("src", src + "?wmode=transparent");
    const width = image.size ?? 400;
    iframe.setAttribute("width", width + '');
    iframe.setAttribute("height", Math.floor(width * videoHeightFactor) + '');
    return iframe;
}

export const graphicFloatTypeToString = (floatType?: GraphicFloatType): string => {
    let float = '';
    if (floatType === GraphicFloatType.left) {
        float = "left";
    } else if (floatType === GraphicFloatType.right) {
        float = "right";
    } else {
        float = "none";
    }
    return float;
}
export const floatToGraphicFloatType = (float: string): GraphicFloatType => {
    if (float === "left") {
        return GraphicFloatType.left;
    }
    if (float === "right") {
        return GraphicFloatType.right;
    }
    return GraphicFloatType.none;
}

/* COMMENTS HERE NEED UPDATING!
    the following can be used to standardize html from external sources, or to parse internally created html for html editor
 * it is called on ALL <img> and <figure> elements by HtmlFormatter class when creating map which is then rendered by the MapRenderer class
    * for external html, all images are assumed to be in <img> elements and videos are not handled:
        float is kept (default to "none")
        if width style is valid it is used as dimension
        if height style is valid it is used as dimension
        data-file is parsed from src attribute; if src starts with "blob" it is respected (this should not happen with external HTML)
    * for internal html, images are children of <figure> elements
    * figure element includes following attributes/styles for images (not necessarily videos):
        height and width styles are used to determine default dimension; width takes priority
        float style is kept, defaults to "none"
        data-file-filename (no domain or subfolders)
        <img> as child of figure is checked for src attribute; it may start with "blob" if the image was inserted from file system
        if <img> contains "alt" attribute it is also kept
    * for videos figure element includes (internal html only):
        data-stream-id=youtube or vimeo id string
        data-stream-source=VideoStreamSource enum ({ youtube = 'Y', vimeo = 'V', none = 'N' })
        data-stream-thumb-src=formatted or fetched src for video thumb (if missing, the src is fetched from vimeo, hence ImageRecord returned as promise
        width style (height is set using standard video aspect ratio)
    * on internally generated html the caption (if any) is formatted as a <figcaption> element child of figure element
    * createImageFromElement takes an <img> or <figure> element and returns its ImageRecord
        if passed <img> it is assumed to be externally generated
        if passed <figure> it is assumed to internally generated and returns image or video
        useful for converting external to internal by storing image record in map and then rendering into standard format
    * IMPORTANT NOTE: Caller must check to see if stream_thumb_src field must be fetched from Vimeo:
            if (image.stream_source === VideoStreamSource.vimeo && !image.stream_thumb_src)
                -- must be fetched in async function --
*/
export const createImageFromElement = (elem: HTMLElement, fileOptions?: ImageFileOptions): ImageRecord | null => {
    const image = { dimension: GraphicDimensionType.width } as ImageRecord;
    if (elem.nodeName === "IMG") {
        // assuming externally generated html
        if (elem.getAttribute('src')) {
            image.filename = deformatImageUrl(elem.getAttribute("src")!, false);
        }
        let width = parseInt(elem.style.width?.replace("px", ''));
        let height = parseInt(elem.style.height?.replace("px", ''));
        // const naturalWidth = img.naturalWidth;
        // const naturalHeight = img.naturalHeight;
        image.dimension = fileOptions?.dimension ?? GraphicDimensionType.width;
        if (image.dimension === GraphicDimensionType.height) {
            // we are going by height; if element did not specify height we need to use default
            if (isNaN(height)) {
                height = fileOptions?.size ?? 200;
            }
            image.size = height;
        } else {
            if (isNaN(width)) {
                width = fileOptions?.size ?? 400;
            }
            image.size = width;
        }
    } else {
        // this is a figure element (internally generated)
        //       console.log("creating image element from:", fig)
        // if this is video all fields are in fig element
        if (elem.getAttribute("data-stream-id")) {
            image.stream_id = elem.getAttribute("data-stream-id") ?? undefined;
            image.stream_source = (elem.getAttribute("data-stream-source") as VideoStreamSource) ?? undefined;
            image.stream_thumb_src = elem.getAttribute("data-stream-thumb-src") ?? undefined;
        } else {
            const img = elem.getElementsByTagName("img")[0];
            const src = img.getAttribute("src")!;
            if (src.startsWith("blob")) {
                image.blob = src;
            }
            image.filename = deformatImageUrl(src, false);
            image.alt = img.getAttribute("alt") ?? undefined;
        }
        // size, float, and caption are found directly in the figure element
        const captionElems = elem.getElementsByTagName("figcaption");
        if (captionElems.length) {
            image.caption = captionElems[0].innerHTML;
        }
        const width = elem.style.getPropertyValue("width");
        image.size = parseInt(width.replace("px", ''));
        image.float = floatToGraphicFloatType(elem.style.float ?? "none");
    }
    //   console.log("createImageFromElement returning image:", image)
    return image;
}

/*
const iframe = document.createElement('iframe');
const src = formatVideoIframeSrc(image);
if (image.stream_source === VideoStreamSource.vimeo) {
    iframe.setAttribute("allow", "autoplay; fullscreen; picture-in-picture");
} else {
    iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share");
}
iframe.setAttribute("allowfullscreen", '');
iframe.setAttribute("src", src);
iframe.setAttribute("width", figSize + '');
iframe.setAttribute("height", Math.floor(figSize * videoHeightFactor) + '');
iframe.setAttribute("data-stream-id", image.stream_id);
iframe.setAttribute("data-stream-source", image.stream_source + '');
fig.appendChild(iframe);
*/