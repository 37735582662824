import React from 'react';
import styled from 'styled-components';

import { iPhoneWidth, StyledErrorText } from './libSupport';
import { genericApiError, useFetchApi } from './useDataApiV2';
import VideoModal from './VideoModal';

import { GraphicFloatType, InfoContentRecord, InfoContentTypeEnum, TextOrImageRecord } from '../interfaces/lib-api-interfaces';

import api from "../api-url";
import { formatImageOrVideoSrcWithFile } from './ImageFormatter';

const InfoContentContainer = styled.div<{ maxWidth: number }>`
    display: flex;
    flex-direction: column;
    max-width: ${props => props.maxWidth}px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    a:link {
        text-decoration: underline;
    }
    h2 {
        font-size: 24px;
        text-align: center;
    }
`
const MainRecordContainer = styled.div`
`
interface InfoContentProps {
    url?: string;           // either this or data MUST be passed
    data?: InfoContentRecord[];
    maxWidth?: number;      // defaults to 600
}
const InfoContent: React.FC<InfoContentProps> = (props) => {
    const [data, setData] = React.useState<InfoContentRecord[]>();
    const [loading, setLoading] = React.useState(false);
    //   const [newGrafIndexes, setNewGrafIndexes] = React.useState<number[]>();
    const [errorMsg, setErrorMsg] = React.useState<string>();

    const { fetch } = useFetchApi();

    React.useEffect(() => {
        if (!loading && props.url && (!data || props.url !== data[0].indexRecord.url)) {
            setLoading(true);
            fetch(api.getInfoContent + props.url, null, (result: InfoContentRecord[]) => {
                //         console.log("result:"); console.log(result);
                setData(result);
            }, () => setErrorMsg(genericApiError));
        }
    }, []);
    React.useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    }, []);
    return (
        <InfoContentContainer maxWidth={props.maxWidth ? props.maxWidth : 600}>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            {data &&
                data.map(record => {
                    return (
                        <InfoContentGroup key={record.indexRecord.info_id} record={record} />
                    )
                })
            }
        </InfoContentContainer>
    )
}
interface InfoContentGroupProps {
    record: InfoContentRecord;
}
const InfoContentGroup: React.FC<InfoContentGroupProps> = (props) => {
    const [videoPlaying, setVideoPlaying] = React.useState<{ youtubeId: string; caption?: string }>();

    const wrapImage = props.record.indexRecord.image && props.record.indexRecord.image.float === GraphicFloatType.right;
    return (
        <MainRecordContainer >
            {wrapImage &&
                <h2>{props.record.indexRecord.title}</h2>
            }
            {props.record.indexRecord.image &&
                <DisplayContentRecord content={{ content_type: InfoContentTypeEnum.image, image: props.record.indexRecord.image }}
                    graphicsSubfolder={props.record.indexRecord.graphics_subfolder} />
            }
            {!wrapImage &&
                <h2>{props.record.indexRecord.title}</h2>
            }
            {props.record.indexRecord.subtitle && <h3>{props.record.indexRecord.subtitle}</h3>}
            {props.record.contents.map((contentRecord, index) => {
                if (contentRecord.content_type === InfoContentTypeEnum.newParagraph) {
                    return <RenderParagraph key={index} contents={props.record.contents} startIndex={index + 1} graphicsSubfolder={props.record.indexRecord.graphics_subfolder}
                        videoLinkClicked={(youtubeId: string, caption?: string) => setVideoPlaying({ youtubeId, caption })} />
                } else if (contentRecord.content_type === InfoContentTypeEnum.image) {
                    return <DisplayContentRecord key={index} graphicsSubfolder={props.record.indexRecord.graphics_subfolder} content={contentRecord} />
                } else {
                    return null;
                }
            })}
            {videoPlaying && <VideoModal youtubeId={videoPlaying.youtubeId} caption={videoPlaying.caption} modalClosed={() => setVideoPlaying(undefined)} />}
        </MainRecordContainer>
    )
}
interface RenderPagraphProps {
    contents: TextOrImageRecord[];
    startIndex: number;
    graphicsSubfolder: string;
    videoLinkClicked?: (youtubeId: string, caption?: string) => void;      // if omitted and there is a video link it will be ignored
}
const RenderParagraph: React.FC<RenderPagraphProps> = (props) => {
    let endIndex = props.startIndex;
    while (endIndex < props.contents.length) {
        if (props.contents[endIndex].content_type === InfoContentTypeEnum.newParagraph) {
            break;
        }
        endIndex++;
    }
    return (
        <p key={props.startIndex}>
            {props.contents.map((record, index) => {
                if (index >= props.startIndex && index < endIndex && record.content_type !== InfoContentTypeEnum.image) {
                    return (
                        <DisplayContentRecord key={index} graphicsSubfolder={props.graphicsSubfolder} content={record} videoLinkClicked={props.videoLinkClicked} />
                    )
                } else {
                    return null;
                }
            })}
        </p>
    )
}
//--------------------------------------
const VideoLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`
interface VideoAttributes {
    youtubeId: string;
    caption?: string;
}
interface DisplayContentRecordProps {
    content: TextOrImageRecord;
    graphicsSubfolder: string;
    videoLinkClicked?: (youtubeId: string, caption?: string) => void;      // if omitted and there is a video link it will be ignored
}
const DisplayContentRecord: React.FC<DisplayContentRecordProps> = (props) => {
    const videoLinkClicked = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (props.videoLinkClicked) {
            const target = e.target as HTMLSpanElement;
            // if caption exists is it appended to url in encoded format
            let caption = undefined;
            let url = target.dataset.url!;
            if (!url.endsWith("/")) {
                // got a caption
                const posn = target.dataset.url!.lastIndexOf('/');
                caption = decodeURIComponent(url.substring(posn + 1));
                url = url.substring(0, posn);
            } else {
                // no caption so chop trailing /
                url = url.slice(0, -1);
            }
            props.videoLinkClicked(url, caption);
        }
    }
    //   console.log("content=", props.content)
    if (props.content.content_type === InfoContentTypeEnum.image) {
        return (
            <ImageWithCaption width={props.content.image!.size!} src={formatImageOrVideoSrcWithFile(props.content.image!, { graphicsSubfolder: props.graphicsSubfolder })}
                caption={props.content.image!.caption} float={props.content.image!.float === GraphicFloatType.right ? "right" : "none"} />
        )
    } else if (props.content.content_type === InfoContentTypeEnum.text) {
        return (
            <span dangerouslySetInnerHTML={{ __html: props.content.text! }} />
        )
    } else if (props.content.content_type === InfoContentTypeEnum.link) {
        return (
            props.content.url!.startsWith("http") ? (
                <a href={props.content.url}>{props.content.text}</a>
            ) : (
                props.content.url!.startsWith("video") ? (
                    <VideoLink data-url={props.content.url!.substring(8)} onClick={videoLinkClicked}>{props.content.text}</VideoLink>
                ) : (
                        <a href={props.content.url!}>{props.content.text}</a>
                )
            )
        )
    } else {
        alert("Invalid content_type: " + props.content.content_type);
        return null;
    }
}
const ImageContainer = styled.div<{ width: number; float: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${props => props.width}px;
    float: ${props => props.float};
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: ${iPhoneWidth}px) {
        max-width: 150px;
    }
   p {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        font-style: italic;
    }
`
const StyledImage = styled.img`
    margin: 4px 4px 0 8px;
    width: 100%;
    height: auto;
`
interface ImageWithCaptionProps {
    width: number;
    src: string;
    caption?: string;
    float?: string;     // default to "none"
}
const ImageWithCaption: React.FC<ImageWithCaptionProps> = (props) => {
    return (
        <ImageContainer width={props.width} float={props.float ? props.float : "none"}>
            <StyledImage src={props.src} alt={props.caption} />
            {props.caption &&
                <p dangerouslySetInnerHTML={{ __html: props.caption }} />
            }
        </ImageContainer>
    )
}
export default InfoContent;