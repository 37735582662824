import { DivisionEnum, MenuItemData } from './interfaces/lib-api-interfaces';
import { RepDataRecord } from './libs/Reps';

const shoppingCart = false;
const isDashboard = false;
export const loginAllowed = false;
export const analytics = false;

const targetDomain = "heartthemoment.com";

export const adminCustId = 1;
export const productGraphicsBaseUrl = (window.location.hostname === "localhost" ? process.env.PUBLIC_URL : ("https://" + targetDomain)) + "/graphics";

// following for store near you if none found
export const orderOnlineUrl = "https://fernsgarden.com/heart-the-moment/";
export const orderOnlineText = "Shop now at fernsgarden.com";

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
const sansFonts = "Arial,Helvetica,sans-serif";
export const themes = {
    defaultFonts: "Verdana,sans-serif",
    navbarFont: "Verdana,sans-serif",
    sansFonts: "Verdana,sans-serif",
    h1FontFamily: "Courier",
    foreColor: "black",
    backColor50: "#f5b1a1",
    backColor25: "#f5d4c3",
    backColor10: "#fff9f7",
    modalStyles: { fontSize: "16px", fontFamily: sansFonts, lineHeight: "24px", padding: "16px" }
};

const trackingFunction = (location: Location) => {
    /*
    const paq = (window as any)._paq;
    paq.push(['setCustomUrl', location.pathname]);
    paq.push(['setDocumentTitle', location.pathname]);
    paq.push(['trackPageView']);
    */
}

export const siteMap: MenuItemData[] = [
    { caption: "Stores", href: "/stores" },
    { caption: "Order online", href: orderOnlineUrl },
    { caption: "Wholesale", href: "/retailers" },
    { caption: "Contact", href: "/contact" },
];

export const nonProductMenu: MenuItemData[] = [
    { caption: "STORE LOCATIONS", href: '', popupItems: [
        { caption: "Find a store", href: "/stores" },
        { caption: "Order online", href: orderOnlineUrl }
    ]},
    { caption: "FOR RETAILERS", href: "/retailers" },
    { caption: "CONTACT", href: "/contact" },
];

export const initialState = {
    mouseMoveHandlers: []
};

// categories are fetched from master page and persisted into local storage
export const facebookUrl = "https://www.facebook.com/Heart-the-Moment-501440043350302/";
export const banner = "PROUDLY MADE IN THE U.S.A";
//export const logoUrl = "other/logo_courier_black_withbg.jpg";
export const logoUrl = "logo_courier_black_withbg.jpg";

export const altLogo = "Heart the Moment";
export const division = DivisionEnum.htm;
export const catalogUrl = productGraphicsBaseUrl + "/catalog/heart_the_moment_catalog_and_pricelist_jan_2024.pdf";
export const companyName = "Heart the Moment";
export const companyPhone = "562-343-1011";
export const alignProducts = "bottom";
export const crownDividerImage = "other/header_crown.jpg";

export default  {
    shoppingCart,
    isDashboard,
    targetDomain,
    loginAllowed,
    analytics,
    adminCustId,
    productGraphicsBaseUrl,
    themes,
    trackingFunction,
    siteMap,
    nonProductMenu,
    initialState,
    facebookUrl,
    banner,
    logoUrl,
    altLogo,
    division,
    catalogUrl,
    companyName,
    companyPhone,
    orderOnlineUrl,
    orderOnlineText,
    alignProducts,
    crownDividerImage
}