import React from 'react';

import Contact, { ContactSectionText } from '../libs/Contact';
import MasterPage from '../libs/MasterPage';

import app from '../appData';
import apiUrl from '../api-url';

const ContactUs: React.FC = () => {
    const htmContactText: ContactSectionText[] = [
        {
            header: "Call, fax or email", paragraphs: [
                "Phone (562) 343-1011",
                "Fax (510) 529-4833",
                'Email <a href="mailto:info@heartthemoment.com">info@heartthemoment.com</a>'
            ]
        },
        {
            header: "Write", paragraphs: [
                "Heart the Moment",
                "1831 Solano Ave Ste A",
                "Berkeley, CA 94707"
            ]
        },
    ];

    return (
        <MasterPage>
            <Contact apiUrl={apiUrl.contact} dividerImageFilename={app.crownDividerImage} text={htmContactText} />
        </MasterPage>
    )
}
export default ContactUs;