import React from 'react';
import styled from 'styled-components';

import { iPhoneWidth, iPadMaxWidth, getNearestParentId } from './libSupport';
import Magnifier from './SamMagnifyV3';
import PhotoViewer from './PhotoViewerWithModal';
import { formatImageUrl, formatVideoIframeSrc } from './ImageFormatter';

import { BuyBoxProductRecord, ImageSizeEnum } from '../interfaces/lib-api-interfaces';

/* props:
    product = {
        caption: string
        price_cents: int
        sku_long: long
        bullet_points: string (newline delimited)
        related: long[] -- skus
        images: string[] -- element 0 is main image filename
        videos: [{ youtube_id: string, caption: string }]
    }
*/
/*  |----MasterContainer---------------------------|
    |-----------ImageAndTextContainer-------|
    |  ImageContainer | TextContainer       |
    |---------------------------------------|
    |-------------------------------------------|    
    |               VideoContainer              |
    --------------------------------------------|
    |               RelatedContainer            |
    |-------------------------------------------|
*/
const MasterContainer = styled.div<{ maxWidth: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: ${props => props.maxWidth}px;
    margin-left: auto;
    margin-right: auto;
    h2 {    /* price */
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 1em;
        font-weight: normal;
        text-align: center;
    }
    h3 { /* quantity label */
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: normal;
        text-align: center;
    }
    h4 { /* stock status header */
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }
    li {    /* bullet points */
        font-size: 18px;
        margin-top: 8px;
    }
    @media (max-width: ${iPhoneWidth}px) {
        h1 {
            font-size: 18px;
            margin-top: .5em;
            margin-bottom: .5em;
        }
        h2 {
            font-size: 18px;
            margin-top: .5em;
        }
        h3 {
            font-size: 16px;
        }
        li {
            font-size: 14px;
            margin-top: 4px;
        }
        p {
            font-size: 14px;
            margin: 0;
            text-align: center;
        }
    }

`
const ImageAndTextContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
const AltImagesContainer = styled.div<{ imgHeight: number; imgPadding: number }>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    cursor: pointer;
    img {
        height: ${props => props.imgHeight}px;
        width: auto;
        padding: ${props => props.imgPadding}px;
        border: 1px solid;
        margin-right: 8px;
    }
`
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50%;
    max-width: 700px;
    @media (max-width: ${iPhoneWidth}px) {
        max-width: ${iPhoneWidth - 20}px;
    }
`
const TextContainer = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
`
const BulletPointText = styled.ul`
    text-align: left;
`
const VideoGrid = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: 100%;
`
const VideoContainer = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    padding: .5%;
    p {
        margin: 8px;
        text-align: center;
    }
`
interface BuyBoxProps {
    product: BuyBoxProductRecord;
    infoBox: React.FC;      // product price, out of stock, qty, add to cart button
    infoBoxProps: Record<string, any>;
}
const BuyBox: React.FC<BuyBoxProps> = (props) => {
    const [mainImageIndex, setMainImageIndex] = React.useState<number>(0);      // index into images
    const [containerWidth, setContainerWidth] = React.useState<number>(0);
    const [imageContainerWidth, setImageContainerWidth] = React.useState<number>(0);
    const [rerender, setRerender] = React.useState(false);

    const containerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const imageContainerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
        //    if (imageContainerRef.current) {
        setImageContainerWidth(imageContainerRef.current!.getBoundingClientRect().width);
        //     }
    }, []);

    const maxWidth = 1200;
    const altImagePadding = 8;
    const altImageHeight = 73;
    const videoWidth = containerWidth * .49;
    const videoHeight = videoWidth / 1.78;
    const ymalImageSize = 200;

    let buttonStyles = { marginLeft: "auto", marginRight: "auto", marginTop: "16px", marginBottom: "16px", width: "70%", height: "48px", fontSize: "24px" };
    if (window.matchMedia("(max-width: " + iPhoneWidth + "px)").matches) {
        buttonStyles.marginTop = "8px";
        buttonStyles.height = "36px";
        buttonStyles.fontSize = "18px";
        buttonStyles.width = "90%";
    }

    const bulletPoints: string[] = props.product.bullet_points ? props.product.bullet_points.split('\n') : [''];

    const handleAltImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
        setMainImageIndex(parseInt(getNearestParentId(e.target as HTMLElement).id));
    }

    const images = props.product.images;

    if (window.matchMedia("(max-width: " + iPadMaxWidth + "px)").matches) {
        // we are on a mobile device
        return (
            <MasterContainer ref={containerRef} maxWidth={maxWidth}>
                <ImageContainer ref={imageContainerRef}>
                    <Magnifier mainImgSrc={formatImageUrl(images[mainImageIndex], { sizeDesignator: ImageSizeEnum.magnified })}
                        width={imageContainerWidth} height={imageContainerWidth} />
                    {images.length > 1 &&
                        <AltImagesContainer imgHeight={altImageHeight} imgPadding={altImagePadding}>
                            {images.map((image, index) => {
                                return (
                                    <img key={image} id={index + ''} onClick={handleAltImageClick} src={formatImageUrl(image, { sizeDesignator: ImageSizeEnum.full })} 
                                        alt={props.product.caption} />
                                )
                            })
                            }
                        </AltImagesContainer>}
                </ImageContainer>
                <TextContainer>
                    <h1>{props.product.caption}</h1>
                    <props.infoBox {...props.infoBoxProps} />
                    <BulletPointText>
                        {bulletPoints.map((text, index) => {
                            return (
                                text ? <li key={index} dangerouslySetInnerHTML={{ __html: text }} /> : null
                            )
                        })}
                    </BulletPointText>
                </TextContainer>
                {props.product.videos && props.product.videos.length > 0 &&
                    <VideoGrid>
                        {props.product.videos.map((video) => {
                            return (
                                <VideoContainer key={video.filename} width={videoWidth}>
                                    <iframe title="video" width={videoWidth} height={videoHeight} src={formatVideoIframeSrc(video)} allowFullScreen></iframe>
                                    <p>{video.caption}</p>
                                </VideoContainer>
                            )
                        })}
                    </VideoGrid>
                }
            </MasterContainer>
        );
    } else {
        // desktop size
        return (
            <MasterContainer ref={containerRef} maxWidth={maxWidth}>
                <ImageAndTextContainer>
                    <ImageContainer ref={imageContainerRef}>
                        <PhotoViewer photos={images} size={imageContainerWidth} onEnlargedChanged={() => setRerender(state => !state)} />
                    </ImageContainer>
                    <TextContainer>
                        <h1>{props.product.caption}</h1>
                        <props.infoBox {...props.infoBoxProps} />
                        <BulletPointText>
                            {bulletPoints.map((text, index) => {
                                return (
                                    text ? <li key={index} dangerouslySetInnerHTML={{ __html: text }} /> : null
                                )
                            })}
                        </BulletPointText>
                    </TextContainer>
                </ImageAndTextContainer>
                {props.product.videos && props.product.videos.length > 0 &&
                    <VideoGrid>
                        {props.product.videos.map((video) => {
                            return (
                                <VideoContainer key={video.filename} width={videoWidth}>
                                    <iframe title="video" width={videoWidth} height={videoHeight} src={formatVideoIframeSrc(video)} frameBorder="0" allowFullScreen></iframe>
                                    <p>{video.caption}</p>
                                </VideoContainer>
                            )
                        })}
                    </VideoGrid>
                }
            </MasterContainer>
        );
    }
}
//                {showUnderstock && <ShowUnderstock qoh={props.product.qoh} showUnderstockSubmit={showUnderstockSubmit} />}

export default BuyBox;
