import React from 'react';
import styled from 'styled-components';
import { ColorResult, RGBColor, SketchPicker } from "react-color";
import SamModal from '../SamModalV2';
import IconButton, { ButtonsRow } from '../IconButtonV2';

const ColorPickerContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const CurrentAndNewBarsAndText = styled.div`
    display: flex;
    flex-direction: column;
    p {
        margin: 0;
    }
`
const CurrentBarAndNewBars = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`
const CurrentOrNewBar = styled.div<{ color: string }>`
    width: 50%;
    height: 20px;
    background-color: ${props => props.color};
    border: 1px solid black;
`
const CurrentAndNewLabels = styled.div`
    display: flex;
    justify-content: space-around;
`
const colorToRgb: Record<string, RGBColor> = {
    white: { r: 0, g: 0, b: 0 },
    red: { r: 255, g: 0, b: 0 },
    lime: { r: 0, g: 255, b: 0 },
    blue: { r: 0, g: 0, b: 255 },
    yellow: { r: 255, g: 255, b: 0 },
    cyan: { r: 0, g: 255, b: 255 },
    aqua: { r: 0, g: 255, b: 255 },
    magenta: { r: 255, g: 0, b: 255 },
    fuchsia: { r: 255, g: 0, b: 255 },
    silver: { r: 192, g: 192, b: 192 },
    gray: { r: 128, g: 128, b: 128 },
    maroon: { r: 128, g: 0, b: 0 },
    olive: { r: 128, g: 128, b: 0 },
    green: { r: 0, g: 128, b: 0 },
    purple: { r: 128, g: 0, b: 128 },
    teal: { r: 0, g: 128, b: 128 },
    navy: { r: 0, g: 0, b: 128 }
};

// the following converts known color to rgb and drops alpha channel if it's 100
export const standardizeColorStyle = (color: string): string => {
    if (color in colorToRgb) {
        color = "rgb(" + colorToRgb[color].r + ", " + colorToRgb[color].g + ", " + colorToRgb[color].b + ")";
    } else {
        // chop off last "100" if there
        const parts = color.replace("rgb(", '').replace(')', '').replaceAll(' ', '').split(',');
        if (parts.length === 4 && parts[3] === "100") {
            color = "rgb(" + parts.join(', ') + ")";
        }
    }
    return color;
}
// NOTE: since this supports alpha, color is passed and returned as "rgb(n,n,n,n)"
// any hex string passed in (starting with "#") is converted to rgba
interface ColorPickerProps {
    color: string;      // hex or rgb
    colorSubmitted: (color: string | null) => void;     // always rgb with gamma channel
}
const ColorPicker: React.FC<ColorPickerProps> = (props) => {
    return (
        <SamModal>
            <ColorPickerModal {...props} />
        </SamModal>
    )
}
const ColorPickerModal: React.FC<ColorPickerProps> = (props) => {
    const colorStringToObject = (colorString: string): RGBColor => {
        let r = '0';
        let g = '0';
        let b = '0';
        let a = '100';
        if (colorString.startsWith('#')) {
            if (colorString.length === 7) {
                r = colorString.substring(1, 3);
                g = colorString.substring(3, 5);
                b = colorString.substring(5, 7);
            } else if (colorString.length === 4) {
                r = '0' + colorString[1];
                g = '0' + colorString[2];
                b = '0' + colorString[3];
            }
        } else if (colorString.startsWith("rgb")) {
            const posn = colorString.indexOf('(');
            const endPosn = colorString.indexOf(')');
            if (posn !== -1 && endPosn !== -1) {
                const rgbParts = colorString.substring(posn + 1, endPosn).replaceAll(' ', '').split(',');
                if (rgbParts.length >= 3) {
                    r = rgbParts[0];
                    g = rgbParts[1];
                    b = rgbParts[2];
                    if (rgbParts.length >= 4) {
                        a = rgbParts[3];
                    }
                }
            }
        }
        return { r: parseInt(r), g: parseInt(g), b: parseInt(b), a: parseInt(a) / 100 };
    }
    const [selectedColor, setSelectedColor] = React.useState(colorStringToObject(props.color));

    const objectToColorString = (color: RGBColor): string => {
        const alpha = color.a === undefined ? 100 : color.a * 100;
        return "rgb(" + color.r + ", " + color.g + ", " + color.b + (alpha === 100 ? '' : (", " + alpha)) + ")";
    }

    const colorChanged = (color: ColorResult) => {
        setSelectedColor(color.rgb);
    }

    return (
        <ColorPickerContainer>
            <SketchPicker color={selectedColor} onChange={colorChanged} />
            <CurrentAndNewBarsAndText>
                <CurrentBarAndNewBars>
                    <CurrentOrNewBar color={objectToColorString(selectedColor)} />
                    <CurrentOrNewBar color={props.color} />
                </CurrentBarAndNewBars>
                <CurrentAndNewLabels>
                    <p>New</p>
                    <p>Current</p>
                </CurrentAndNewLabels>
            </CurrentAndNewBarsAndText>
            <ButtonsRow height={30}>
                <IconButton caption="Use this color" onClick={() => props.colorSubmitted(objectToColorString(selectedColor))} />
                <IconButton caption="Cancel" onClick={() => props.colorSubmitted(null)} />
            </ButtonsRow>
        </ColorPickerContainer>
    )
}
export default ColorPicker;