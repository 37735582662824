// V5 adds header field, isVertical prop, and uses FormMgr rather than usFormMgr
import React from 'react';
import styled from 'styled-components';
import deepcopy from 'deepcopy';

import IconButton, { ButtonsRow } from '../IconButtonV2';
import DatePicker from './DatePicker';
import RifmNumeric from './RifmNumeric';
import { getNearestParentId, mergeOptionsRecords, measureText } from '../libSupport';
import FormMgr from './FormMgr';
import { useGlobalContext } from '../SamState';
import ColorPicker from './ColorPicker';

import { IconButtonV2Props } from '../../interfaces/lib-react-interfaces';
import { FormFieldRecord, FormComboFieldRecord, CustomValidatorCallback, CustomFormatterCallback, FormFieldType, FormFieldSizingRecord, StyleRecord, FormFieldGetInputPropsType } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';
import FontPicker, { FontCalloutRecord, calloutRecordToFontString, styleValueToCalloutRecord } from './FontPicker';

const MasterContainer = styled.div<{ maxWidth: number; fontSize: number; justify: string; direction: string }>`
    width: 100%;
    max-width: ${props => props.maxWidth}px;
    font-size: ${props => props.fontSize}px;
    flex-direction: ${props => props.direction};
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    display: flex;
    justify-content: ${props => props.justify};
    flex-wrap: wrap;
    padding-left: 1%;
    padding-right: 1%;

`
// 1 label/input/error box
const InputBox = styled.div<{ widthPct: number; marginTop?: number; maxWidth?: string; marginLeft?: number; marginRight?: number }>`
    width: ${props => props.widthPct}%;     /* field.size!.widthPct or 100 */
    margin-top: ${props => props.marginTop}px;
    max-width: ${props => props.maxWidth};
    margin-left: ${props => props.marginLeft ?? 8}px;
    margin-right: ${props => props.marginRight ?? 0}px;
    display: flex;
    flex-direction: column;     /* rows are: label, input, error */
    justify-content: flex-start;
`
//-----------------
const StyledLabelAndRequiredRow = styled.div<{ fontSize: number; lineHeight: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${props => props.fontSize}px;
    line-height: ${props => props.lineHeight}px;
    color: #666;
`
const LabelText = styled.span`
    margin-bottom: 2px;
`
//-----------------
const StyledInput = styled.input<{ borderColor: string; height: number; width?: number }>`
    border: 1px solid ${props => props.borderColor};     /* #666 or red if error */
    height: ${props => props.height}px;
    width: ${props => props.width}px;
`
const StyledTextArea = styled.textarea<{ borderColor: string; height: number; backColor: string }>`
    border: 2px solid ${props => props.borderColor};     /* #666 or red if error */
    height: ${props => props.height}px;
    background-color: ${props => props.backColor};      /* should be backColor25 */
    border-style: outset;
`
const StyledSelect = styled.select<{ inputHeight: number }>`
    height: ${props => props.inputHeight}px;
    border: 1px solid #ccc;
`
const StyledLinkText = styled.p<{ marginTop?: number }>`
    text-align: left;
    cursor: pointer;
    width: 100%;
    text-decoration: underline;
    margin-top: 0;
    margin-bottom: 0;
    :hover {
        font-style: italic;
    }
`
const StyledHorizontalField = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
`
const CheckboxCaption = styled.span`
    text-align: left;
    margin-left: 8px;
`
const HeaderText = styled.p<{ fontSize: number }>`
    font-size: ${props => props.fontSize}px;
    text-align: center;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 0;
`
export const recordToStyleRecord = (record: Record<string, any>): StyleRecord => {
    const styles: StyleRecord = {};
    for (const prop in record) {
        styles[prop] = (typeof record[prop] === "number") ? (record[prop] + "px") : record[prop];
    }
    return styles;
}
export const styleRecordToRecord = (styles: StyleRecord): Record<string, any> => {
    const record: Record<string, any> = {};
    for (const prop in styles) {
        record[prop] = styles[prop].endsWith("px") ? parseInt(styles[prop].slice(0, -2)) : styles[prop];
    }
    return record;
}
/* place following code in MasterPage:
    const location = useLocation();
    const forms = new FormMgr(setContext);
    React.useEffect(() => {
        forms.clear();
    }, [location]);
*/
//-----------------
// const Buttons = styled.div`
//     display: flex;
//     width: 100%;
//     height: 2.5rem;
//     justify-content: center;
//     margin-top: 16px;
// `
export interface SamFormButton {
    caption: string;
    id: string;
    icon?: string;
    style?: Record<string, any>;
    validate?: boolean;
}
// inputHeight?: number;   // height of input field in pixels (applies to text and numbers only) -- used as default on fields without this prop
// marginTop?: number;    // vertical space between fields -- used as default on fields without this prop

export interface SamFormProps {
    id: string;
    fields: FormFieldRecord[];
    fontSize?: number;          // default to 14px
    headerFontSize?: number;    // isVertical must true and headers must be included in field array
    labelFontSize?: number;     // default to 85% of fontSize
    fieldSizing?: FormFieldSizingRecord;        // defaults; can use per field to override this
    maxWidth?: number;          // default to 1000
    justify?: string;           // default to "space-between"
    isVertical?: boolean;
    initialValues?: Record<string, any> | null;     // can be incorporated into field object using "initialValue" property
    submitButtons?: SamFormButton[];
    createSubmitButton?: boolean;       // this and next are shortcuts to avoid passing submitButtons; handleSubmit is called with id of "submit"
    createCancelButton?: boolean;       // handleSubmit is called with value null

    onChange?: (values: Record<string, any>) => void;
    onFieldBlur?: (values: Record<string, any>, name: string) => void;      // for applying values as they are input
    handleSubmit?: (values: Record<string, any> | null, id: string) => void;      // shows submit button and returns validated values if passed; id is id of button element (not form)
    customFormatter?: CustomFormatterCallback;
    customValidator?: CustomValidatorCallback;
    linkClicked?: (e: React.MouseEvent<HTMLElement>) => void;
    inlineButtonClicked?: (fieldName: string, value: string) => void;
    getPaymentInputProps?: FormFieldGetInputPropsType;
    hideField?: (fieldName: string, values: Record<string, any>) => boolean;

    backColor?: string;     // applies to modal only
    title?: string;         // modal only
}
const SamForm: React.FC<SamFormProps> = (props) => {
    const id = props.id;
    const forms = new FormMgr(useGlobalContext().setContext);
    const maxWidth = props.maxWidth ? props.maxWidth : 1000;
    const fontSize = props.fontSize ? props.fontSize : 14;
    const labelFontSize = props.labelFontSize ? props.labelFontSize : fontSize * .85;
    const headerFontSize = props.headerFontSize ?? 18;
    let buttons: IconButtonV2Props[] | null = null;

    const setButtonMarginLeft = (button: IconButtonV2Props, margin: number) => {
        if (button.style) {
            if (!button.style.leftMargin) {
                button.style.marginLeft = "16px";
            }
        } else {
            button.style = { marginLeft: "16px" };
        }
    }

    const initButtons = (): IconButtonV2Props[] => {
        const submitButtons: IconButtonV2Props[] = [];
        if (props.submitButtons) {
            props.submitButtons.forEach(b => {
                submitButtons.push({ ...b });
            });
        }
        if (props.createSubmitButton) {
            // we want a default submit button
            if (submitButtons.find(b => b.id === "submit")) {
                throw "SamFormV5: Cannot create submit button; it already exists";
            } else {
                submitButtons.push({ caption: "Submit", id: "submit", icon: "fas fa-check", validate: true });
            }
        }
        if (props.createCancelButton) {
            if (submitButtons.find(b => b.id === "cancel")) {
                throw "SamFormV5: Cannot create cancel button; it already exists";
            } else {
                submitButtons.push({ caption: "Cancel", id: "cancel", icon: "fas fa-ban", validate: false });
            }
        }
        submitButtons.forEach((b: IconButtonV2Props, index: number) => {
            if (index > 0) {
                setButtonMarginLeft(b, 16);
            }
        });
        return submitButtons;
    }

    if (props.submitButtons || props.createSubmitButton || props.createCancelButton) {
        buttons = initButtons();
    }

    const fieldSizes: FormFieldSizingRecord = {
        height: 24, marginLeft: 16, marginTop: 8
        // widthPct?: number;
        // widthPx?: number;
    }
    // return a copy of fields array with all properties initialized
    // sizing properties are propagated from global defaults as appropriate
    const initFieldsAndValues = () => {
        const fields = deepcopy(props.fields) as FormFieldRecord[];
        const initialValues = {} as Record<string, any>;
        const defaultSizing = { ...fieldSizes };
        fields.forEach(field => {
            if (field.type === FormFieldType.digitsOnly && (!field.validator || !field.validator.maxLength)) {
                const err = field.name + ": field.validator.maxLength required on digitsOnly fields";
                throw err;
            }
            field.size = mergeOptionsRecords([fieldSizes, defaultSizing, field.size]) as FormFieldSizingRecord;
            if (field.size.widthFromLabel) {
                field.size.widthPx = measureText(field.label!, labelFontSize + "px " + (app.themes.sansFonts ?? "Arial,Helvetica,sans-serif")) + 4;
            } else {
                if (field.width) {
                    field.size.widthPct = field.width;
                }
                if (field.fixedWidth) {
                    field.size.widthPx = field.fixedWidth;
                }
                if (!("widthPct" in field.size) && !("widthPx" in field.size)) {
                    field.size.widthPct = 48;
                }
            }
            if (!field.type) {
                field.type = FormFieldType.text;
            }
            if (field.visible === undefined) {
                field.visible = true;
            }
            if (field.button) {
                setButtonMarginLeft(field.button, 16);
            }
            if (field.initialValue) {
                initialValues[field.name] = field.initialValue;
            }
        });
        //     console.log("fields:", fields)
        const defaultValues = props.initialValues ? mergeOptionsRecords([initialValues, props.initialValues]) : initialValues;
        forms.setFormFieldsAndValues(id, fields, defaultValues);
    }

    React.useEffect(() => {
        // fields are set once and not checked every render, there will not be a re-render after setting them (at least with multiple forms on page)
        if (!forms.getFormFields(id)) {
            initFieldsAndValues();
            //      console.log("fields:", forms.getFormFields(id));
        }
    });

    // called when any button clicked; could be created or passed in
    const handleButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const target = getNearestParentId(e.target as HTMLElement);
        const button = buttons!.find(b => target.id === b.id) as IconButtonV2Props;
        if (button.validate && !forms.validateForm(id, props.customValidator)) {
            return;
        }
        // at this point form is valid or button is a no-validate button
        const values = target.id === "cancel" ? null : forms.getFormValues(id);
        props.handleSubmit && props.handleSubmit(values, target.id);
    }
    if (!forms.getFormFields(id)) {
        return null;
    }

    const isHorizontalField = (fieldType: FormFieldType): boolean => {
        const horizontalFields: FormFieldType[] = [FormFieldType.link, FormFieldType.checkbox, FormFieldType.button];
        return horizontalFields.includes(fieldType);
    }

    //   console.log("fields:", forms.getFormFields(props.id));
    return (
        <MasterContainer maxWidth={maxWidth} fontSize={fontSize} justify={props.justify ?? "space-between"} direction={props.isVertical ? "column" : "row"}>
            {forms.getFormFields(props.id).map(field => {
                if (field.visible && (!props.hideField || !props.hideField(field.name, forms.getFormValues(props.id)))) {
                    const error = forms.getError(props.id, field.name);
                    return (
                        props.isVertical ? (
                            <InputBox key={field.name} widthPct={97} marginTop={field.size!.marginTop} marginLeft={0}
                                maxWidth={"100%"}>
                                {field.type === FormFieldType.header ? (
                                    <HeaderText fontSize={headerFontSize}>{field.label}</HeaderText>
                                ) : (
                                    <>
                                        <VerticalInputRow {...props} forms={forms} fontSize={fontSize} labelFontSize={labelFontSize} field={field} isError={!!error} />
                                        {error && <ErrorRow text={error} align="center" />}
                                    </>
                                )}
                            </InputBox>
                        ) : (
                            <InputBox key={field.name} widthPct={field.size!.widthPct ? field.size!.widthPct : 97} marginTop={field.size!.marginTop}
                                maxWidth={field.size!.widthPx + "px"} marginLeft={field.size!.marginLeft} marginRight={field.size!.marginRight}>
                                {!isHorizontalField(field.type!) && field.label &&
                                    <LabelAndRequiredRow label={field.label} fontSize={labelFontSize} required={!!(field.validator && field.validator.required)} />
                                }
                                <InputField {...props} forms={forms} field={field} isError={!!error} />
                                {error && <ErrorRow text={error} align="left" />
                                }
                            </InputBox>
                        )
                    )
                } else {
                    return null;
                }
            })}
            {buttons &&
                <ButtonsRow>
                    {buttons.map((button) => {
                        return (
                            <IconButton {...button} key={button.id} onClick={handleButtonClicked} />
                        )
                    })}
                </ButtonsRow>
            }
        </MasterContainer >
    )
}
//-------------------------------------------------------
interface InputFieldProps {
    id: string;
    field: FormFieldRecord;
    forms: FormMgr;
    isError: boolean;
    linkClicked?: (e: React.MouseEvent<HTMLElement>) => void;
    onChange?: (values: Record<string, any>) => void;
    inlineButtonClicked?: (fieldName: string, value: string) => void;
    customFormatter?: CustomFormatterCallback;
    onFieldBlur?: (values: Record<string, any>, name: string) => void;      // for applying values as they are input
    getPaymentInputProps?: FormFieldGetInputPropsType;
}
const InputField: React.FC<InputFieldProps> = (props): any => {
    const inlineButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        //   console.log("inlineButtonClcked");
        const target = e.target as HTMLButtonElement;
        const field = props.forms.getField(props.id, target.id) as FormFieldRecord;
        let value = props.forms.getValue(props.id, field.name);
        props.inlineButtonClicked!(field.name, value);
    }
    const handleChange = (value: string | number | boolean, name: string) => {
        //        console.log("handleChange on " + name + ", value=" + value)
        const field = props.forms.getField(props.id, name) as FormFieldRecord;
        if (field.validator) {
            if (field.validator.isAllUpper) {
                value = (value as string).toUpperCase();
            }
            if (field.validator.maxLength) {
                value = (value as string).substring(0, field.validator.maxLength);
            }
        }
        if (props.forms.getValue(props.id, field.name) !== value) {
            const newValues = props.forms.setValue(props.id, field.name, value);
            if (props.onChange) {
                props.onChange(newValues);
            }
            props.forms.deleteError(props.id, field.name);
        }
    }
    const handleFontSubmitted = (callout: FontCalloutRecord | null, name: string) => {
        if (callout) {
            handleChange(calloutRecordToFontString(callout), name);
        }
    }
    const getFormattedValue = (field: FormFieldRecord): string => {
        let value: string = props.forms.getValue(props.id, field.name);
        if (props.customFormatter) {
            const formatted = props.customFormatter(field.name, value);
            if (formatted) {
                return formatted;
            }
        }
        return value;
    }
    const handleFieldBlur = (e: any) => {
        const target = e.target as HTMLInputElement;
        props.onFieldBlur && props.onFieldBlur(props.forms.getFormValues(props.id), target.id);
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const target = e.target as HTMLInputElement;
        handleChange(target.value, target.id);
    }
    const handleCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as any;
        handleChange(target.checked as boolean, target.id);
    }
    switch (props.field.type) {
        case FormFieldType.text:
            return <StyledInput id={props.field.name} borderColor={props.isError ? "red" : "#ccc"} width={props.field.size!.widthPx ? props.field.size!.widthPx : undefined}
                value={getFormattedValue(props.field)} height={props.field.size!.height!} placeholder={props.field.placeholder}
                type={props.field.validator && props.field.validator.isPassword ? "password" : "text"}
                onChange={handleInputChange} onBlur={handleFieldBlur} />
        case FormFieldType.multiLine:
            return <StyledTextArea id={props.field.name} borderColor={props.isError ? "red" : "#ccc"}
                value={getFormattedValue(props.field)} height={props.field.size!.height!} placeholder={props.field.placeholder}
                backColor={props.field.multiLineBackColor ?? app.themes.backColor25 ?? "white"} onChange={handleInputChange} onBlur={handleFieldBlur} />
        case FormFieldType.combo:
            return (
                <StyledSelect id={props.field.name} inputHeight={props.field.size!.height!} value={getFormattedValue(props.field)}
                    onChange={handleInputChange} onBlur={handleFieldBlur}>
                    {props.field.comboSource!.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>{option.caption ?? option.value}</option>
                        );
                    })}
                </StyledSelect>
            )
        case FormFieldType.color:
            return <ColorInput initialColor={props.forms.getValue(props.id, props.field.name)} colorSubmitted={color => handleChange(color, props.field.name)} />
        case FormFieldType.link:
            return (
                <StyledLinkText id={props.field.name} onClick={props.linkClicked}>{props.field.label!}</StyledLinkText>
            )
        case FormFieldType.checkbox:
            return (
                <StyledHorizontalField>
                    <input id={props.field.name} type="checkbox" value='' checked={props.forms.getValue(props.id, props.field.name)}
                        onChange={handleCheckedChanged} onBlur={handleFieldBlur} />
                    <CheckboxCaption>{props.field.label}</CheckboxCaption>
                </StyledHorizontalField>
            )
        case FormFieldType.button:
            return (
                <StyledHorizontalField>
                    <span dangerouslySetInnerHTML={{ __html: props.field.label ? props.field.label : '' }} /><span>&nbsp;</span>
                    <StyledInput id={props.field.name} borderColor={props.isError ? "red" : "#ccc"} width={props.field.size!.widthPx}
                        value={getFormattedValue(props.field)} height={props.field.size!.height!} placeholder={props.field.placeholder}
                        type={props.field.validator && props.field.validator.isPassword ? "password" : "text"}
                        onChange={handleInputChange} />
                    {props.field.button &&
                        <IconButton id={props.field.name} caption={props.field.button.caption} style={props.field.button.style} icon={props.field.button.icon}
                            onClick={inlineButtonClicked} />
                    }
                </StyledHorizontalField>
            )
        case FormFieldType.reactPaymentInputs:
            return (
                <input id={props.field.name} {...(props.getPaymentInputProps!(props.field.name, { onChange: handleInputChange }))} value={getFormattedValue(props.field)} />
            )
        case FormFieldType.date:
            return (
                <DatePicker name={props.field.name} value={props.forms.getValue(props.id, props.field.name)} onChange={handleChange} />
            )
        case FormFieldType.fontFamily:
            return (
                <FontPicker id={props.id + "font"} width={80} selectedCallout={styleValueToCalloutRecord(props.forms.getValue(props.id, props.field.name))}
                    fontSubmitted={(callout: FontCalloutRecord | null) => handleFontSubmitted(callout, props.field.name)} />
            )
        case FormFieldType.phone:
            return (
                <RifmNumeric name={props.field.name} fieldType={props.field.type} initialValue={props.forms.getValue(props.id, props.field.name)} height={props.field.size!.height!}
                    borderColor={props.isError ? "red" : "#ccc"} textAlign={"left"}
                    onChange={handleChange} onBlur={handleFieldBlur} />
            )
        case FormFieldType.int:
        case FormFieldType.decimal:
            return (
                <RifmNumeric name={props.field.name} fieldType={props.field.type} initialValue={props.forms.getValue(props.id, props.field.name)}
                    borderColor={props.isError ? "red" : "#ccc"}
                    placeholder={props.field.placeholder} height={props.field.size!.height!}
                    onChange={handleChange} onBlur={handleFieldBlur} />
            )
        case FormFieldType.digitsOnly:
            return (
                <RifmNumeric name={props.field.name} fieldType={props.field.type} initialValue={props.forms.getValue(props.id, props.field.name)}
                    borderColor={props.isError ? "red" : "#ccc"}
                    placeholder={props.field.placeholder} height={props.field.size!.height!} textAlign={"left"}
                    onChange={handleChange} onBlur={handleFieldBlur} />
            )
    }
    return null;
}
//-------------------------------------------------------
const ColorInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`
const CurrColorBoxAndLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const CurrColorBox = styled.div<{ backColor: string }>`
    width: 40px;
    height: 18px;
    background-color: ${props => props.backColor};
    border: 1px solid black;
`
const CurrColorLabel = styled.p`
    margin: 0;
    margin-left: 8px;
`
const ClickToChangeText = styled.p`
    margin: 0;
`
interface ColorInputProps {
    initialColor: string;
    colorSubmitted: (color: string) => void;
}
const ColorInput: React.FC<ColorInputProps> = (props) => {
    const [pickerShown, setPickerShown] = React.useState(false);

    const colorClicked = () => {
        setPickerShown(true);
    }
    const colorSubmitted = (color: string | null) => {
        setPickerShown(false);
        if (color) {
            props.colorSubmitted(color);
        }
    }
    return (
        <>
            <ColorInputContainer onClick={colorClicked}>
                <CurrColorBoxAndLabel>
                    <CurrColorBox backColor={props.initialColor} />
                    <CurrColorLabel>{props.initialColor}</CurrColorLabel>
                </CurrColorBoxAndLabel>
                <ClickToChangeText>(click to change)</ClickToChangeText>
            </ColorInputContainer>
            {pickerShown && <ColorPicker color={props.initialColor} colorSubmitted={colorSubmitted} />}
        </>
    )
}
//-------------------------------------------------------
const VerticalInputRowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
const VerticalInputLabel = styled.label<{ fontSize: number }>`
    display: flex;
    justify-content: flex-end;
    width: 50%;
`
const VerticalInputContainer = styled.div`
    width: 50%;
`
const InputFieldContainer = styled.div<{ width: string }>`
    display: flex;
    justify-content: flex-start;
    width: ${props => props.width};
    margin-left: 4px;
`
interface VerticalInputRowProps extends InputFieldProps {
    labelFontSize: number;     // default to 85% of fontSize
    fontSize: number;
}
const VerticalInputRow: React.FC<VerticalInputRowProps> = (props) => {
    const label = props.field.type !== FormFieldType.checkbox && props.field.label
        ? (!!(props.field.validator && props.field.validator.required) ? '*' : '') + props.field.label + ':'
        : '';
    return (
        <VerticalInputRowContainer>
            <VerticalInputLabel fontSize={props.labelFontSize}>{label}</VerticalInputLabel>
            <VerticalInputContainer>
                <InputFieldContainer width={props.field.size!.widthPx ? (props.field.size!.widthPx + "px") : "100%"}>
                    <InputField {...props} />
                </InputFieldContainer>
            </VerticalInputContainer>
        </VerticalInputRowContainer>
    )
}
//-------------------------------------------------------
interface LabelAndRequiredRowProps {
    label: string;
    required: boolean;
    fontSize: number;
}
// common component for inputs where label is above input area
const LabelAndRequiredRow: React.FC<LabelAndRequiredRowProps> = (props) => {
    return (
        <StyledLabelAndRequiredRow fontSize={props.fontSize} lineHeight={props.fontSize * 1.2}>
            <LabelText dangerouslySetInnerHTML={{ __html: props.label }} />
            {props.required &&
                <span>
                    REQUIRED
                </span>
            }
        </StyledLabelAndRequiredRow>
    )
}
//-------------------------------------------------------
/* ErrorRow
    text: string (optional; return null if no text)
*/
const ErrorText = styled.span<{ align: string }>`
    font-size: 16px;
    color: red;
    line-height: 20px;
    margin-top: 4px;
    text-align: ${props => props.align};
`
interface ErrorRowProps {
    text?: string;
    align: string;
}
const ErrorRow: React.FC<ErrorRowProps> = (props) => {
    if (props.text) {
        return (
            <ErrorText align={props.align}>{"x " + props.text}</ErrorText>
        )
    } else {
        return null;
    }
}

export const selectMonthList: FormComboFieldRecord[] = [
    { caption: "", value: "0" },
    { caption: "01 - January", value: "1" },
    { caption: "02 - February", value: "2" },
    { caption: "03 - March", value: "3" },
    { caption: "04 - April", value: "4" },
    { caption: "05 - May", value: "5" },
    { caption: "06 - June", value: "6" },
    { caption: "07 - July", value: "7" },
    { caption: "08 - August", value: "8" },
    { caption: "09 - September", value: "9" },
    { caption: "10 - October", value: "10" },
    { caption: "11 - November", value: "11" },
    { caption: "12 - December", value: "12" },
];
export const selectYearList = (): FormComboFieldRecord[] => {
    let list = [{ caption: "", value: "0" }];
    let year = new Date().getFullYear();
    for (let yr = year; yr < year + 10; yr++) {
        list.push({ caption: yr.toString(), value: (yr + '').slice(-2) });
    }
    return list;
}

export default SamForm;
