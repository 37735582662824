import { DivisionEnum } from "./interfaces/lib-api-interfaces";

const fetchOrder = "/api/getPendingOrder";        // since HTM has no online ordering this is a placeholder only
const fetchProducts = "/api/products/";     // + division
const fetchCategories = "/api/categoriesAsArray/"; // + division
const contact = "/api/contact";
const login= "/api/login";
const search = "/api/wsSearch";
const storeNearYou = "/api/store_near_you";
const postShipTo = "/api/postShipTos";
const submitOrder = "/api/postOrder";
const cancelOrder = "/api/cancelOrder";
const getInfoContent = "/api/getInfoContent/" + DivisionEnum.wholesale + "/";

// dashboard
const uploadImage = "/api/uploadImage";

// dummies
const saveAddressBook = "";     // not used for HTM
const fetchOrderUrl = "";

export default {
    fetchOrder,
    fetchProducts,
    fetchCategories,
    contact,
    login,
    search,
    storeNearYou,
    postShipTo,
    submitOrder,
    cancelOrder,
    getInfoContent,
    uploadImage,
    saveAddressBook,
    fetchOrderUrl
}