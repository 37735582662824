// general support and constants for library functions and components
import styled from 'styled-components';

import { RowState, StyleRecord } from '../interfaces/lib-api-interfaces';
import deepcopy from 'deepcopy';

export const writeLog = (isLogging: boolean, text: string, obj: any) => {
    if (isLogging) {
        console.log(text, obj);
    }
}

export const Divider = styled.div<{ color: string; width: string; height: number; margin: number }>`
    height: ${props => props.height}px;
    background-color: ${props => props.color};
    width: ${props => props.width};
    margin: ${props => props.margin}px auto ${props => props.margin}px auto
`
// margin-left: 50%;
// transform: translate(-50%);

export const formatItemNo = (itemNo: string): string => {
    return itemNo.substring(0, 1) + "-" + itemNo.substring(1, 4);
}
// convert e.g. 1322400700 to "13224-7"
export const skuLongToFormattedMin = (skuLong: number): string => {
    const productId = Math.floor(skuLong / 100000);
    const spec1Id = Math.floor((skuLong - productId * 100000) / 100);
    const spec2Id = skuLong % 100;
    let formatted = productId + '';
    if (spec2Id) {
        formatted += ('-' + spec1Id + '-' + spec2Id);
    } else if (spec1Id) {
        formatted += ('-' + spec1Id);
    }
    return formatted;
}
export const formattedSkuToLong = (formatted: string): number => {
    const parts = formatted.split('-');
    let skuLong = parseInt(parts[0]) * 100000;
    if (parts.length > 1) {
        skuLong += (parseInt(parts[1]) * 100);
    }
    if (parts.length > 2) {
        skuLong += parseInt(parts[2]);
    }
    return skuLong;
}
export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const miniLayoutThreshhold = 900;       // when screen width is below this switch to "mini" layout with nav bar below logo
export const smallFontLayoutThreshhold = 1100;  // when screen width is below this use smaller font for menus

export const desktopWidth = 1200;      // desktop machine
export const iPadMinWidth = 768;
export const iPadMaxWidth = 834;    // some iPads are 1024
export const iPhoneWidth = 414;


export const sleep = (milliseconds: number) => {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}
export const StyledErrorText = styled.h4`
    text-align: center;
    width: 100%;
    color: red;
    font-size: 18px;
`
export const insert = (str: string, index: number, value: string) => {
    return str.substring(0, index) + value + str.substr(index);
}
export const replaceAll = (source: string, search: string, replace: string): string => {
    return source.split(search).join(replace);
}
export const deformat = (value: string): string => {
    return value.replace(/\W/g, '');
}
export const formatPhone = (value: string): string => {
    return value.substring(0, 3) + "-" + value.substring(3, 6) + "-" + value.substring(6, 10);
}
// change spaces to dashes and remove apostrophes
export const formatTextForHtml = (caption: string) => {
    return replaceAll(replaceAll(caption.toLowerCase(), "'", ''), " ", "-");
}
export const isNumber = (value: string | number): boolean => {
    return !isNaN((value as number) - parseFloat(value as string));
}
export const isAllNumeric = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        if (value.charAt(i) < '0' || value.charAt(i) > '9') {
            return false;
        }
    }
    return true;
}
export const getNearestParentId = (target: HTMLElement): HTMLElement => {
    while (!target.id) {
        target = target.parentElement as HTMLElement;
    }
    return target;
}
// caller can create canvas and pass it for repeated calls
export const measureText = (text: string, font: string, passedCanvas?: HTMLCanvasElement): number => {
    //    console.log("measureText(" + text + ", " + font);
    // re-use canvas object for better performance
    const canvas = passedCanvas ? passedCanvas : document.createElement("canvas");
    const context = canvas.getContext("2d");
    context!.font = font;
    const metrics = context!.measureText(text);
    return metrics.width + 4;
}
export function copyArrayWithUpdatedElement<T>(list: T[], newItem: T, updateIndex: number): T[] {
    const newList = list.map((item, index) => {
        if (index === updateIndex) {
            return newItem;
        }
        return item;
    });
    return newList;
}
export function copyArrayWithoutDeleted<T>(list: T[]): T[] {
    const newList: T[] = [];
    list.forEach(item => {
        if (((item as Record<string, any>).rowState & RowState.deleted) === 0) {
            newList.push(item);
        }
    });
    return newList;
}
export const isObject = (value: any): boolean => {
    return value && typeof value === 'object'
}
//------------- STYLE SUPPORT ----------------------------
// passed in array has first element fallback default styles, followed by higher priority
// record 2 overrides 1 which overrides 0
// e.g.: record 0 is basic defaults, record 1 is app.themes, record 2 is props
// array must have at least one member
export const mergeOptionsRecords = (optionsRecords: (Record<string, any> | undefined)[], fieldName?: string): Record<string, any> => {
    const styles = deepcopy(optionsRecords[0]) as Record<string, any>;
    for (let i = 1; i < optionsRecords.length; i++) {
        if (optionsRecords[i]) {
            mergeRecords(styles, optionsRecords[i]!);
        }
    }
    return styles;
}
// copy source into target without destroying anything in source
const mergeRecords = (target: Record<string, any>, source: Record<string, any>) => {
    for (const prop in source) {
        if (isObject(source[prop])) {
            if (!(prop in target)) {
                target[prop] = {};
            }
            mergeRecords(target[prop], source[prop]);
        } else {
            target[prop] = source[prop];
        }
    }
}
//-----------------------------------------------------------

