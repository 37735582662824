import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MasterPage from '../libs/MasterPage';
import { Divider } from '../libs/Contact';
import { formatImageUrl } from '../libs/ImageFormatter';

import app from '../appData';

const StyledContainer = styled.div<{ foreColor: string }>`
    max-width: 500px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    p {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: black;
        margin-top: 0;
        margin-bottom: 0;
    }
    h2 {
        font-size: 16px;
    }
    h3 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;
        color: ${props => props.foreColor};
    }
    a:link {
        text-decoration: underline;
    }
`
const Retailers: React.FC = () => {
    return (
        <MasterPage>
            <h1>Become a Retailer of Heart the Moment</h1>
            <StyledContainer foreColor={app.themes.foreColor}>
                <h2>If you own a retail store, it is easy to order from Heart the Moment!</h2>
                <Divider src={formatImageUrl(app.crownDividerImage)} />
                <h3>Call, fax or email your order</h3>
                <p>Phone (562) 343-1011</p>
                <p>Fax (510) 529-4833</p>
                <p>Email <a href="mailto:orders@heartthemoment.com" target="_blank" rel="noopener noreferrer">orders@heartthemoment.com</a></p>
                <Divider src={formatImageUrl(app.crownDividerImage)} />
                <h3>Find a rep</h3>
                <p><Link to="/reps">Click here to view our current rep list.</Link></p>
                <Divider src={formatImageUrl(app.crownDividerImage)} />
                <h3>Heart the Moment is now on Faire!</h3>
                <p>If you are not yet a Faire buyer, you may qualify for a $100 credit, free freight and Net 60 terms.
                    <a href="https://heartthemoment.faire.com" target="_blank" rel="noopener noreferrer"> Visit our Faire store to find out!</a></p>
                <Divider src={formatImageUrl(app.crownDividerImage)} />
                <h3>View our catalog</h3>
                <p><Link to="/catalog">Click here to view a PDF Heart the Moment catalog</Link></p>
            </StyledContainer>
        </MasterPage>
    );
}

export default Retailers;


