import deepcopy from 'deepcopy';

import { FormFieldRecord, CustomValidatorCallback, FormFieldType } from '../../interfaces/lib-api-interfaces';

export const isValidEmail = (email: string) => {
    return email && (/(.+)@(.+){2,}\.(.+){2,}/).test(email);
}

/* following functions are public:
    getFormFields
    getField
    getFormValues
    getValue
    setValue
    getError
    deleteError
    setFormFieldsAndValues
    validateForm
*/

// the master record property is always the form id; the child record property is the field name; the child record value is string for error or field value for form
type FormOrErrorState = Record<string, Record<string, any>>;
// property is form id; value is the fields belonging to that form
type FieldsState = Record<string, FormFieldRecord[]>;

/*
    values: FormOrErrorState;
    fields: FieldsState;
    errors: FormOrErrorState;
*/

const valuesKey = "formValues";
const fieldsKey = "formFields";
const errorsKey = "formErrors";
const rerenderKey = "formMgr";

export const initForms = (store: { setContext: (key: string, data: any) => void }) => {
    // console.log("initForms:", store, "; fields:");
    // store.setContext(valuesKey, {});
    // store.setContext(fieldsKey, {});
    // store.setContext(errorsKey, {});
}

class FormMgr {
    setContext: (key: string, data: any) => void;

    constructor(setContext: (key: string, data: any) => void) {
        if (!sessionStorage.getItem(fieldsKey)) {
            this.clearForms();
        }
        this.setContext = setContext;
    }
    _getSessionStore = (key: string): any => {
        const rawData: string | null = sessionStorage.getItem(key);
        return rawData ? JSON.parse(rawData) : null;
    }
    _setSessionStore = (key: string, data: any, doNotRerender?: boolean) => {
        const currStringified = sessionStorage.getItem(key);
        const newstringified = JSON.stringify(data);
        if (currStringified !== null && currStringified === newstringified) {
            return;
        }
        sessionStorage.setItem(key, newstringified);
        if (!doNotRerender) {
            this.setContext(rerenderKey, Date.now());
        }
    }
    // following is called upon route change to simulate keeping session store in global context
    clearForms = () => {
        this._setSessionStore(valuesKey, {}, true);
        this._setSessionStore(fieldsKey, {}, true);
        this._setSessionStore(errorsKey, {}, true);
    }

    //------- CONTEXT ------------
    _getContext = (key: string, isCopy: boolean): FormOrErrorState | FieldsState => {
        const state = this._getSessionStore(key);
        if (isCopy) {
            return state ? deepcopy(state) : null;
        }
        return state;
    }
    _setContext = (key: string, data: FormOrErrorState | FieldsState) => {
        this._setSessionStore(key, data);
    }

    //------- FIELDS ------------
    getFormFields = (formId: string): FormFieldRecord[] => {
        const fields = this._getSessionStore(fieldsKey) as FieldsState;
        return fields ? deepcopy(fields[formId]) : null;
    }
    getField = (formId: string, fieldName: string): FormFieldRecord | undefined => {
        const field = this._findField(formId, fieldName);
        return field ? deepcopy(field) : null;
    }
    _findField = (formId: string, fieldName: string): FormFieldRecord | undefined => {
        const fields = this._getSessionStore(fieldsKey) as FieldsState;
        return fields[formId].find(field => field.name === fieldName);
    }

    //------- VALUES ------------
    getFormValues = (formId: string): Record<string, any> => {
        const values = this._getSessionStore(valuesKey) as FormOrErrorState;
        const formValues = deepcopy(values[formId]) as Record<string, any>;
        const fields = this._getSessionStore(fieldsKey) as FieldsState;
        for (const fieldName in formValues) {
            if (fields[formId].find(field => field.name === fieldName)?.type === FormFieldType.header) {
                delete formValues[fieldName];
            }
        }
        return formValues;
    }
    getValue = (formId: string, fieldName: string): any => {
        const values = this._getSessionStore(valuesKey) as FormOrErrorState;
        if (!(formId in values) || !(fieldName in values[formId])) {
            return '';
        }
        return values[formId][fieldName];
    }
    // returns updated form values object for given form
    setValue = (formId: string, fieldName: string, value: any): Record<string, any> => {
        const values = this._getSessionStore(valuesKey) as FormOrErrorState;
        values[formId][fieldName] = value;
        this._setSessionStore(valuesKey, values);

        return values[formId];
    }
    updateFormValues = (formId: string, newValues: Record<string, any>) => {
        const values = this._getSessionStore(valuesKey) as FormOrErrorState;
        for (const fieldName in newValues) {
            values[formId][fieldName] = newValues[fieldName];
        }
        this._setSessionStore(valuesKey, values);
    }

    //------- ERRORS ------------
    getError = (formId: string, fieldName: string): string => {
        const errors = this._getSessionStore(errorsKey) as FormOrErrorState;
        if (!errors[formId] || !errors[formId][fieldName]) {
            return '';
        }
        return errors[formId][fieldName];
    }
    deleteError = (formId: string, fieldName: string) => {
        const errors = this._getSessionStore(errorsKey) as FormOrErrorState;
        if (errors[formId] && fieldName in errors[formId]) {
            delete errors[formId][fieldName];
            this._setSessionStore(errorsKey, errors);
        }
    }
    _setFormErrors = (formId: string, formErrors: Record<string, string>) => {
        const errors = this._getSessionStore(errorsKey) as FormOrErrorState;
        errors[formId] = formErrors;
        this._setSessionStore(errorsKey, errors);
    }

    //------- FIELDS AND VALUES ------------
    setFormFieldsAndValues = (formId: string, formFields: FormFieldRecord[], formValues: Record<string, any>) => {
        const fields = this._getSessionStore(fieldsKey) as FieldsState;
        fields[formId] = deepcopy(formFields);
        const values = this._getSessionStore(valuesKey) as FormOrErrorState;
        values[formId] = this._cleanValues(formValues, fields[formId]);
        this._setSessionStore(fieldsKey, fields);
        this._setSessionStore(valuesKey, values);
    }
    // eliminate all null values so controlled forms will work; return copy of values
    _cleanValues = (formValues: Record<string, any>, formFields: FormFieldRecord[]): Record<string, any> => {
        const clean = {} as Record<string, any>;
        formFields.map(field => {
            if (!formValues[field.name]) {
                if (field.type === FormFieldType.checkbox) {
                    clean[field.name] = false;
                } else if (field.type === FormFieldType.int || field.type === FormFieldType.decimal || field.type === FormFieldType.fixedPoint) {
                    clean[field.name] = 0;
                } else {
                    clean[field.name] = '';
                }
            } else {
                clean[field.name] = formValues[field.name];
            }
        });
        return clean;
    }
    static extractValuesFromFields = (fields: FormFieldRecord[]): Record<string, any> => {
        const record: Record<string, any> = {};
        for (const field of fields) {
            if (field.type !== FormFieldType.header) {
                record[field.name] = field.initialValue;
            }
        }
        return record;
    }


    //------- VALIDATE ------------
    // return true if form validates
    validateForm = (formId: string, customValidator: CustomValidatorCallback | null = null): boolean => {
        const fields = this._getSessionStore(fieldsKey) as FieldsState;
        const values = this._getSessionStore(valuesKey) as FormOrErrorState;
        const newErrors = this._validateAllFields(formId, fields[formId], values[formId], customValidator);
        this._setFormErrors(formId, newErrors);
        return !Object.keys(newErrors).length;
    }
    _validateAllFields = (formId: string, fields: FormFieldRecord[], values: Record<string, any>, customValidator: CustomValidatorCallback | null): Record<string, string> => {
        const newErrors: Record<string, string> = {};
        for (const field of fields) {
            let errorMessages = this._validateField(formId, field, values, customValidator);      // this combines standard validation with custom user validation (if any)
            if (errorMessages.length > 0) {
                newErrors[field.name] = errorMessages[0];
            }
        }
        return newErrors;
    }
    // return an array of error messages
    // return empty array if no errors
    _validateField = (formId: string, field: FormFieldRecord, values: Record<string, any>, customValidator: CustomValidatorCallback | null) => {
        let messages = [];
        let value = values[field.name];
        const v = field.validator;
        // start with caller provided validators
        if (v && v.required && !value) {
            messages.push(field.label + " is required");
            return messages;
        }
        if (v && v.minLength && (!value || value.length < v.minLength)) {
            messages.push("Must be at least " + v.minLength + " characters");
        }
        if (field.name.includes("email") && value && !isValidEmail(value)) {
            messages.push("Please enter a valid email address");
        }
        if (messages.length === 0 && customValidator) {
            const msg = customValidator(field.name, values);
            if (msg) {
                messages.push(msg);
            }
        }
        return messages;
    }
}
export default FormMgr;
