import React from 'react';
import styled from 'styled-components';

import { getNearestParentId, iPhoneWidth } from './libSupport';

import { ImageSizeEnum } from '../interfaces/lib-api-interfaces';
import { formatImageUrl } from './ImageFormatter';

const animationMs = 500;

const ModalParent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: white;
    z-index: 100;
`
// following could hold "X" or chevrons
const ModalButton = styled.div<{ left: number; top: number }>`
    font-size: 36px;
    position: fixed;
    left: ${props => props.left}px;
    top: ${props => props.top}px;
`
// ImageContainer includes alt images
const ImageContainer = styled.div<{ width: number }>`
    display: flex;
    flex-direction: column;
    width: ${props => props.width}px;
    margin-right: 32px;
    @media (max-width: ${iPhoneWidth}px) {
        max-width: ${iPhoneWidth - 20}px;
`
// holds main image only
const MainImageContainer = styled.div<{ size: number; showBorder: boolean }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${props => props.showBorder ? "1px solid" : null};
    padding: 4px;
    margin: 8px;
    z-index: 200;
`
const AltImagesContainer = styled.div<{ imgHeight: number; imgPadding: number }>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    cursor: pointer;
    img {
        height: ${props => props.imgHeight}px;
        width: auto;
        padding: ${props => props.imgPadding}px;
        border: 1px solid;
        margin-right: 8px;
    }
`
interface PhotoViewerProps {
    photos: string[];      // start with first one as main image
    size: number;               // height and width in px
    onEnlargedChanged: (isEnlarged: boolean) => void;       // caller must use this to hide and restore nav bar for clean look
}
const PhotoViewer: React.FC<PhotoViewerProps> = (props) => {
    const [mainImageIndex, setMainImageIndex] = React.useState<number>(0);      // index into props.photos
    const [modalActive, setModalActive] = React.useState(false);
    const [enlarging, setEnlarging] = React.useState(false);
    const [enlargedRect, setEnlargedRect] = React.useState<{ x: number, y: number, width: number }>();      // for positioning "X" and chevrons

    const mainImageContainerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const mainImageRef = React.useRef<HTMLImageElement>() as React.MutableRefObject<HTMLImageElement>;

    const altImagePadding = 8;
    const altImageHeight = 73;

    React.useEffect(() => {
        if (enlarging) {
            const rect = mainImageContainerRef.current.getBoundingClientRect();
            const newHeight = Math.min(window.innerHeight - 45, rect.height * 2);
            const newWidth = Math.min(window.innerWidth - 20, rect.width * 2);
            const newSize = Math.min(newHeight, newWidth);
            const scale = newSize / rect.width;
            const offset = newSize - rect.width;

            const targetX = (window.innerWidth - newSize) / 2;
            const targetY = (window.innerHeight - newSize) / 2 + 25;
            const scaledX = rect.x - offset / 2;
            const scaledY = rect.y - offset / 2;
            const translateX = targetX - scaledX;
            const translateY = targetY - scaledY;
            setEnlargedRect({ x: targetX, y: targetY, width: newSize });

            mainImageContainerRef.current.style.transform = "translate(" + translateX + "px, " + translateY + "px) scale(" + scale + ")";
            mainImageContainerRef.current.style.transition = animationMs + "ms ease-in-out";
            setTimeout(() => setModalActive(true), animationMs);
        } else {
            mainImageContainerRef.current.style.transform = '';
            setModalActive(false);
        }
    }, [enlarging]);

    const handleAltImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
        setMainImageIndex(parseInt(getNearestParentId(e.target as HTMLElement).id));
    }
    // if following is incorporated into css, Chrome shows blank image (Firefox is fine)
    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const mainImage = mainImageRef.current;
        mainImage.style.maxWidth = "100%";
        mainImage.style.maxHeight = mainImage.style.maxWidth;
        mainImage.style.height = "auto";
        mainImage.style.width = "auto";
        mainImage.style.cursor = "pointer";
    }

    const imageClicked = () => {
        if (enlarging) {
            handleEnlargedClose();
        } else {
            handleEnlargeOpen();
        }
    }

    const handleEnlargeOpen = () => {
        setEnlarging(true);
        props.onEnlargedChanged(true);
    }
    const handleEnlargedClose = () => {
        setEnlarging(false);
        props.onEnlargedChanged(false);
    }
    const handleEnlargedBack = () => {
        const index = mainImageIndex - 1;
        setMainImageIndex(index < 0 ? props.photos.length - 1 : index);
    }
    const handleEnlargedForward = () => {
        setMainImageIndex((mainImageIndex + 1) % props.photos.length);
    }

    return (
        <ImageContainer width={props.size}>
            {modalActive &&
                <ModalParent>
                    <ModalButton left={enlargedRect!.x + enlargedRect!.width - 30} top={enlargedRect!.y - 30} onClick={handleEnlargedClose}>
                        <i className="far fa-window-close" />
                    </ModalButton>
                    {props.photos.length > 1 &&
                        <React.Fragment>
                            <ModalButton left={enlargedRect!.x - 30} top={enlargedRect!.y + enlargedRect!.width / 2 - 15} onClick={handleEnlargedBack}>
                                <i className="fas fa-chevron-circle-left" />
                            </ModalButton>
                            <ModalButton left={enlargedRect!.x + enlargedRect!.width} top={enlargedRect!.y + enlargedRect!.width / 2 - 15} onClick={handleEnlargedForward}>
                                <i className="fas fa-chevron-circle-right" />
                            </ModalButton>
                        </React.Fragment>
                    }
                </ModalParent>
            }
            <MainImageContainer ref={mainImageContainerRef} size={props.size} showBorder={!modalActive}>
                <img ref={mainImageRef} src={formatImageUrl(props.photos[mainImageIndex], { sizeDesignator: modalActive ? ImageSizeEnum.magnified : ImageSizeEnum.full })}
                    onLoad={imageLoaded} onClick={imageClicked} />
            </MainImageContainer>
            {props.photos.length > 1 &&
                <AltImagesContainer imgHeight={altImageHeight} imgPadding={altImagePadding}>
                    {props.photos.map((filename, index) => {
                        return (
                            <img key={filename} id={index + ''} onClick={handleAltImageClick} src={formatImageUrl(filename, { sizeDesignator: ImageSizeEnum.full })} />
                        )
                    })
                    }
                </AltImagesContainer>}
        </ImageContainer>

    )
}
export default PhotoViewer;