import React from 'react';
import styled from 'styled-components';

import { useFetchApi } from './useDataApiV2';
import SamForm from '../libs/forms/SamFormV5';
import MasterPage from './MasterPage';
import '../App.css';
import { StyledErrorText } from './libSupport';
import { selectStateList } from './selectStateList';

import { StoreNearYouRecord } from '../interfaces/ws-api-interfaces';
import { FormFieldRecord, FormFieldType } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import api from '../api-url';
import { useGlobalContext } from './SamState';
import FormMgr from './forms/FormMgr';

/* SamForm should have props for:
    handleSubmit (required)
    submitCaption (if not passed the submit button is omitted)
    submitWidth
    width (recommended)
    align (formatting and alignment):
        vertical-center: each element on its own line, all centered
        vertical-left: each element on its own line, all left
        justify-center: label and input on same lines, label flush right to center, input flush left to center
        left: label and input on same lines, flush left
        center: label and input on same lines, centered together
    labelColor
    inputColor
    labelFontSize
    inputFontSize
    labelFontFamily
    inputFontFamily


  required props on each field:
    name
    label
    default
    size

    optional on each field:
    isAllUpper
    maxLength
    isAllNumeric
    required
    radioList (list of radio buttons)
    optionsList (list of drop down selections)
*/

/* api returns the following:
    [
        public string company { get; set; }
        public string city { get; set; }
        public string phone { get; set; }
        public string url { get; set; }
    ]
*/
const StyledContainer = styled.div`
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`
const StyledTable = styled.table`
    margin-top: 8px;
    border: 2px solid;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    border-collapse: collapse;
    a:link {
        text-decoration: underline !important;
    }
`
const StyledCaption = styled.tr<{ backColor: string }>`
    font-weight: bold;
    font-size: 14px;
    background-color: ${props => props.backColor};
`
const StyledRow = styled.tr<{ backColor: string }>`
    background-color: ${props => props.backColor};
`
const StyledCell = styled.td`
    padding: 4px;
`
const StyledMessageText = styled.p`
    font-size: 14px;
    text-align: center;
    width: 100%;
`

const Stores: React.FC = () => {
    const [apiUrl, setApiUrl] = React.useState<string>();
    const [storeData, setStoreData] = React.useState<StoreNearYouRecord[]>();
    const [errorMsg, setErrorMsg] = React.useState<string>('');
    const [fetchingUrl, setFetchingUrl] = React.useState<string>();

    const { fetch, isFetchLoading } = useFetchApi();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const fetchFailed = () => {
        setErrorMsg("We're sorry but our store list seems to be offline right now. Please try again later.");
    }
    const handleChangeCallback = (input: Record<string, any>) => {
        const url = api.storeNearYou + "/" + app.division + "/" + input["stateAbbrev"];
        if (!apiUrl || apiUrl !== url) {
            setApiUrl(url);
        }
        setFetchingUrl(url);
        return true;
    }
    React.useEffect(() => {
        if (fetchingUrl) {
            fetch(fetchingUrl, null, (data: StoreNearYouRecord[]) => setStoreData(data), fetchFailed);
            setFetchingUrl(undefined);
        }
    }, [fetchingUrl]);

    // if condition is set, that condition must be set to true via setCondition or field will not be rendered
    const formData: FormFieldRecord[] = [
        { name: "stateAbbrev", type: FormFieldType.combo, label: "Choose your state", comboSource: selectStateList },
    ];
    return (
        <MasterPage>
            <h1>Find a Store</h1>
            <StyledContainer>
                <SamForm id="stores" fields={formData} onChange={handleChangeCallback} />
            </StyledContainer>
            {errorMsg ? (
                <StyledErrorText>We're sorry but our store list seems to be offline right now. Please try again later.</StyledErrorText>
            ) : (
                isFetchLoading(apiUrl) ? (<i className="fa fa-spinner fa-spin spinner48"></i>
                ) : (
                    storeData && (
                        storeData.length === 0 ? (
                            <StyledMessageText>We do not have a store in your state, however you are welcome to order online
                                at <a href={app.orderOnlineUrl}><u>fernsgarden.com</u></a></StyledMessageText>
                        ) : (
                            <StyledTable>
                                <tbody>
                                    <StyledCaption backColor={app.themes.backColor50}>
                                        <StyledCell>Store name</StyledCell><StyledCell>City</StyledCell><StyledCell>Phone</StyledCell></StyledCaption>
                                    {storeData.map((store, index) => {
                                        return (
                                            <StyledRow key={index} backColor={index % 2 ? app.themes.backColor25 : app.themes.backColor10}>
                                                {store.url ? (
                                                    <StyledCell><a target="_blank" rel="noopener noreferrer" href={store.url}>{store.company}</a></StyledCell>
                                                ) : (
                                                    <StyledCell>{store.company}</StyledCell>
                                                )}
                                                <StyledCell>{store.city}</StyledCell>
                                                <StyledCell>{store.phone}</StyledCell>
                                            </StyledRow>
                                        );
                                    })}
                                </tbody>
                            </StyledTable>)
                    ))
            )}
        </MasterPage>
    )
}

export default Stores;