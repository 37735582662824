import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { usePostApi } from './useDataApiV2';
import SamForm from '../libs/forms/SamFormV5';
import { ModalMessageBox, ModalMessageResponseFlags } from './SamModalV2';

import { FormFieldRecord, FormFieldType } from '../interfaces/lib-api-interfaces';

import '../App.css';
import app from '../appData';
import { formatImageUrl } from './ImageFormatter';

const StyledContainer = styled.div<{ foreColor: string }>`
    text-align: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    p {
        font-size: 14px;
        line-height: 22px;
        color: black;
        margin-top: 0;
        margin-bottom: 0;
    }
    h2 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;
        color: ${props => props.foreColor};
    }
`
const FormDiv = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
`
export const Divider = styled.img`
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 4px;
`

export interface ContactSectionText {
    header: string | null;
    paragraphs: string[];       // html tags allowed inside
}
interface ContactProps {
    dividerImageFilename: string;
    text: ContactSectionText[];
    apiUrl: string;
}
const Contact: React.FC<ContactProps> = (props) => {
    const [resultMsg, setResultMsg] = React.useState<string>();
    const [modalText, setModalText] = React.useState<string>();
    const { post, isPostLoading } = usePostApi();
    const navigate = useNavigate();

    const postSucceeded = () => {
        setModalText("Your comment or question has been submitted. Thanks for your interest in " + app.companyName + "!");
    }
    const postFailed = () => {
        setModalText("We're so sorry. We are having problems processing your request right now. Please try again later or call us at " + app.companyPhone + ".");
    }
    const handleSubmit = (inputs: Record<string, any> | null) => {
        if (isPostLoading()) {
            return;
        }
        if (inputs) {
            inputs.division = app.division;
            inputs.reason = "Customer sent a comment or question";
            post(props.apiUrl, inputs, postSucceeded, postFailed);
        } else {
            navigate("/");
        }
    }
    // if condition is set, that condition must be set to true via setCondition or field will not be rendered
    const formData: FormFieldRecord[] = [
        { name: "name", label: "Your name", width: 48, validator: { required: true, maxLength: 50 } },
        { name: "email", label: "Your email", width: 48, validator: { required: true, maxLength: 100 } },
        { name: "phone", label: "Your phone", width: 48 },
        { name: "order_id", label: "Order # (if this is about an order)", width: 48 },
        { name: "comment", label: "Comment or question (750 character maximum)", type: FormFieldType.multiLine, size: { height: 200 }, validator: { required: true, maxLength: 750 } },
    ];
    return (
        <>
            <h1>Contact {app.companyName}</h1>
            {resultMsg && <p>{resultMsg}</p>}
            <StyledContainer foreColor={app.themes.color!}>
                {props.text.map((section, sectionIndex) => {
                    return (
                        <React.Fragment key={sectionIndex}>
                            <Divider src={formatImageUrl(props.dividerImageFilename)} alt="divider" />
                            {section.header && <h2>{section.header}</h2>}
                            {section.paragraphs.map((graf, index) => {
                                return (
                                    <p key={index} dangerouslySetInnerHTML={{ __html: graf }} />
                                )
                            })}
                        </React.Fragment>
                    )
                })}
                <Divider src={formatImageUrl(props.dividerImageFilename)} alt="divider" />
                <h2>Submit a question or comment:</h2>
                <FormDiv>
                    <SamForm id="contact" fields={formData} submitButtons={[{ id: "submit", caption: "Submit comment or question", icon: "fas fa-check" }]}
                        handleSubmit={handleSubmit} />
                </FormDiv>
            </StyledContainer>
            {modalText && <ModalMessageBox caption={modalText} responseFlags={ModalMessageResponseFlags.ok} onSubmit={() => navigate("/")} />}
        </>
    );
}
export default Contact;
