import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { themes, division } from '../appData';
import { useTokens } from './SamState';
import { usePostApi } from './useDataApiV2';

import { WSSearchResultRecord } from '../interfaces/ws-api-interfaces';

import api from '../api-url';

const StyledSearchIcon = styled.div`
    font-size: 32px;
`
const StyledSearchBoxAndStatus = styled.div<{ left: number }>`
    position: absolute;
    z-index: 1000;
    left: ${props => props.left}px;
    top: 48px;
    width: 300px;
`
const StyledSearchBox = styled.input<{ width: number }>`
    width: ${props => props.width}px;
`
const StyledSearchStatus = styled.div`
`
const StyledSearchErrorText = styled.p<{ backColor: string }>`
    margin: 16px;
    margin-top: 0;
    color: red;
    font-weight: bold;
    border: solid 1px red;
    background-color: ${props => props.backColor};
`

const SearchPopup: React.FC = () => {
    const searchBoxWidth = 200;

    const { post, isPostLoading } = usePostApi();
    const { getToken } = useTokens();
    const navigate = useNavigate();

    const [searchBoxShown, setSearchBoxShown] = React.useState<boolean>(false);
    const [currSearchText, setCurrSearchText] = React.useState<string>();
    const [noHitsShown, setNoHitsShown] = React.useState<boolean>(false);
    const [iconRect, setIconRect] = React.useState<Record<string, any>>();
    const [searchBoxLeft, setSearchBoxLeft] = React.useState<number>(-20);
    const searchBoxRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    React.useEffect(() => {
        if (searchBoxShown) {
            const right = searchBoxRef.current.getBoundingClientRect().right;
            setSearchBoxLeft(-20 - (right > window.innerWidth ? right - window.innerWidth + 30 : 0));
        }
    }, [searchBoxShown]);

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            doSearch();
        }
    }
    const handleMouseEnter = () => {
        if (!searchBoxShown) {
            setSearchBoxShown(true);
        }
    }
    const handleMouseLeave = () => {
        if (noHitsShown) {
            setNoHitsShown(false);
        }
    }
    const handleIconClicked = () => {
        if (searchBoxRef.current) {
            doSearch();
        }
    }
    const postSucceeded = (result: WSSearchResultRecord[]) => {
        if (result.length === 0) {
            if (!noHitsShown) {
                setNoHitsShown(true);
            }
        } else {
            if (noHitsShown) {
                setNoHitsShown(false);
            }
            handleMouseLeave();
            navigate("/search", { state: result });
        }
    }
    const postFailed = () => {
        if (!noHitsShown) {
            setNoHitsShown(true);
        }
    }
    const doSearch = () => {
        const searchText = searchBoxRef.current.value;
        if (searchText !== currSearchText) {
            post(api.search, { searchText, division: division, excludePrepacks: !getToken() }, postSucceeded, postFailed);
            setCurrSearchText(searchText);
        }
    }

    return (
        <React.Fragment>
            <StyledSearchIcon onMouseOver={handleMouseEnter} onClick={handleIconClicked}>
                <i className="fas fa-search" />
            </StyledSearchIcon>
            {searchBoxShown && (
                <StyledSearchBoxAndStatus left={searchBoxLeft} onMouseLeave={handleMouseLeave}>
                    <StyledSearchBox ref={searchBoxRef} placeholder="Search" width={searchBoxWidth} onKeyDown={handleSearchKeyDown} />
                    <StyledSearchStatus>
                        {isPostLoading(api.search) && <i className="fa fa-spinner fa-spin spinner48"></i>}
                        {noHitsShown &&
                            <StyledSearchErrorText backColor={themes.backColor10}>No matching products found.</StyledSearchErrorText>
                        }
                    </StyledSearchStatus>
                </StyledSearchBoxAndStatus>
            )}
        </React.Fragment>
    );

}

export default SearchPopup;