import React from 'react';

import Reps, { RepDataRecord } from '../libs/Reps';

import app from '../appData';

const HtmReps: React.FC = () => {
    const repList = [
        new RepDataRecord("It's 4 U", "Minnetonka, MN", "952-451-4772", "", "loriapoehler@msn.com", "", "Iowa and Minnesota"),
        new RepDataRecord("TLM Associates", "Somerville, MA", "617-821-5362", "", "tom.lena@tlmsales.com", "http://www.tlmsales.com", "Connecticut, Maine, Massachusetts, New Hampshire, Rhode Island, Vermont")
    ];
        
    return (
        <Reps companyName={app.companyName} text={repList} />
    )
}
export default HtmReps;