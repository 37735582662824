import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// routes
import Home from './components/Home';
import Search from './libs/Search';
import Products from './libs/Products';
import PartyBoxes from './components/PartyBoxes';
import Stores from './libs/Stores';
import ContactUs from './components/ContactUs';
import Catalog from './libs/Catalog';
import Retailers from './components/Retailers';
import Reps from './components/HtmReps';
import Login from './libs/Login';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products/:category/:item" element={<Products />} />
            <Route path="/products/:category" element={<Products />} />
            <Route path="/party-boxes" element={<PartyBoxes />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/search" element={<Search />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/retailers" element={<Retailers />} />
            <Route path="/reps" element={<Reps />} />
            <Route path="/login" element={<Login />} />
        </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;


/* uncomment this to implement tracking
    const history = useHistory();
    React.useEffect(() => {
        trackPageView();                // To track the first pageview upon load
        history.listen(trackPageView); // To track the subsequent pageviews
    }, [history]);
    function trackPageView() {
        window.ga("set", "page", window.location.pathname);
        window.ga("send", "pageview");
    } 
    */
