import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MasterPage from '../libs/MasterPage';
import { useSessionStore } from '../libs/SamState';
import { miniLayoutThreshhold } from '../libs/libSupport';
import { formatImageUrl } from '../libs/ImageFormatter';

//const app = require('../appData');
import app from '../appData';
import { MenuItemData } from '../interfaces/lib-api-interfaces';
import InfoContent from '../libs/InfoContent';

const HomeImage = styled.img` 
    width: 100%;
    height: auto;
    margin-bottom: 8px;
`
const StyledFooterContainer = styled.div<{ foreColor: string; backColor: string }>`
    color: ${props => props.foreColor};
    background-color: ${props => props.backColor};
    display: flex;
    justify-content: space-between;
    margin: 16px auto 0 auto;
    max-width: 1000px;
    flex-wrap: wrap;
`
const StyledFooterSection = styled.div<{ marginLeft?: number; marginRight?: number; textAlign: string; lineHeight: number }>`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 12px;
    margin-left: ${props => props.marginLeft}px;
    margin-right: ${props => props.marginRight}px;
    text-align: ${props => props.textAlign};
    line-height: ${props => props.lineHeight}px;
    font-size: 14px;
    @media (max-width: ${miniLayoutThreshhold}px) {
        font-size: 12px;
    }
`
const StyledSectionHeader = styled.p`
    font-weight: bold;
    margin-bottom: 4px;
    line-height: 12px;
`
const InfoDiv = styled.div`
    margin-top: 8px;
`
const Divider = styled.div<{ backColor: string }>`
    background-color: ${props => props.backColor};
    height: 1px;
    width: 100%;
    max-width: 1200px;
`
const menuLineHeight = 12;
const menuAlign = "left";
const infoLineHeight = 18;
const infoAlign = "center";
const indent = 80;


const Home: React.FC = () => {
    const [menu, setMenu] = React.useState<MenuItemData[]>();
    const { getSessionStore } = useSessionStore();

    const menuLoaded = () => {
        setMenu(getSessionStore("menu") as MenuItemData[]);
    }

    return (
        <MasterPage menuLoaded={menuLoaded}>
            {menu &&
                <React.Fragment>
                    <Divider backColor={app.themes.backColor25} />
                    <Link to="/products/tiaras"><HomeImage src={formatImageUrl("other/bwslide.jpg")} alt="Heart the Moment" /></Link>
                    <InfoContent url={'about-htm'} maxWidth={900} />
                    <StyledFooterContainer foreColor={app.themes.foreColor} backColor={app.themes.backColor25}>
                        <StyledFooterSection textAlign={menuAlign} lineHeight={menuLineHeight} marginLeft={indent}>
                            <StyledSectionHeader>Products</StyledSectionHeader>
                            <div>
                                {menu[0].popupItems!.map((mapEntry) => {
                                    return (
                                        <Link to={mapEntry.href!} key={mapEntry.caption}><p>{mapEntry.caption}</p></Link>
                                    );
                                })}
                            </div>
                        </StyledFooterSection>
                        <StyledFooterSection textAlign={menuAlign} lineHeight={menuLineHeight}>
                            <StyledSectionHeader>Navigate</StyledSectionHeader>
                            <div>
                                {app.siteMap.map((mapEntry, index) => {
                                    if (mapEntry.href!.startsWith("http")) {
                                        return (
                                            <a href={mapEntry.href!} target="_blank" rel="noopener noreferrer" key={index}><p>{mapEntry.caption}</p></a>
                                        )
                                    }
                                    return (
                                        <Link to={mapEntry.href!} key={index}><p>{mapEntry.caption}</p></Link>
                                    );
                                })}
                            </div>
                        </StyledFooterSection>
                        <StyledFooterSection textAlign={infoAlign} lineHeight={infoLineHeight} marginRight={indent}>
                            <StyledSectionHeader>Info</StyledSectionHeader>
                            <InfoDiv>
                                Heart the Moment<br />
                                1831 Solano Ave Ste A<br />
                                Berkeley, CA 94707<br />
                                562-343-1011<br />
                                info@heartthemoment.com<br />
                            </InfoDiv>
                        </StyledFooterSection>
                    </StyledFooterContainer>
                </React.Fragment>
            }
        </MasterPage>
    );
}

export default Home;