import React from 'react';
import styled from 'styled-components';

import MasterPage from '../libs/MasterPage';
import { formatImageUrl } from '../libs/ImageFormatter';

const partyBoxCopy = `Having a party? Every guest can don their own paper tiara or crown with Heart the Moment Party Boxes. 
    Box of 10 assorted tiaras, one size fits all with included elastic band. Party down and delight in the moment!`

const StyledMasterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const StyledProductContainer = styled.div`
    flex: 1;
    width: 50%;
    max-width: 500px;
`
const StyledImage = styled.img`
    width: 100%;
    height: auto;
`
const StyledCaption = styled.p`
    margin-top: 0;
    margin-bottom: 8px;
    width: 100%;
    font-size: 18px;
    text-align: center;
`
const StyledCopy = styled.p`
    font-size: 14px;
    line-height: 22px;
    max-width: 940px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 750px) {
        font-size: 12px;
        line-height: 16px;
    }
`

const PartyBoxes:React.FC = () => {
    return (
        <MasterPage>
            <h1>10 Piece Tiara Party Box</h1>
            <StyledCopy>{partyBoxCopy}</StyledCopy>
            <StyledMasterContainer>
                <StyledProductContainer>
                    <StyledImage src={formatImageUrl("j-as1.jpg")} alt="10 piece tiara party box" />
                    <StyledCaption>Party Box<br />J-AS1</StyledCaption>
                </StyledProductContainer>
                <StyledProductContainer>
                    <StyledImage src={formatImageUrl("j-as1p.jpg")} alt="Box included" />
                    <StyledCaption>Packed in<br />corrugated box</StyledCaption>
                </StyledProductContainer>
            </StyledMasterContainer>
        </MasterPage>
    );
}

export default PartyBoxes;
