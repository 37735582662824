import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import MasterPage from './MasterPage';
import { themes } from '../appData';
import { formatItemNo } from './libSupport';
import { formatImageUrl } from './ImageFormatter';

import { WSSearchResultRecord } from '../interfaces/ws-api-interfaces';
import { ImageSizeEnum } from '../interfaces/lib-api-interfaces';

const StyledMasterContainer = styled.div<{ foreColor: string }>`
    display: flex;
    flex-wrap: wrap;
    color: ${props => props.foreColor};
    position: relative;
    justify-content: center;
`
const StyledProductCard = styled.div`
    margin-left: 16px;
    cursor: pointer;
`
const StyledCaptionLine = styled.p`
    margin-top: 4px;
    margin-bottom: 4px;
`
const StyledThumb = styled.img`
    width: auto;
    height: 250px;
`
// this is launched from SearchPopup via router after search results come back with at least one result
const Search: React.FC = () => {
    const [results, setResults] = React.useState<WSSearchResultRecord[]>();
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => window.scrollTo(0, 0));

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
  //      console.log("clicked on " + e.target.id)
        navigate("/products/" + formatItemNo(target.id));
    }

    React.useEffect(() => {
        const searchResults: WSSearchResultRecord[] = location.state as WSSearchResultRecord[];
   //     console.log("results=", searchResults)
        if (searchResults) {
            if (searchResults.length === 1) {
                navigate("/products/" + formatItemNo(searchResults[0].item_no[0]));
            } else {
                setResults(searchResults);
            }
        } else {
            navigate("/");
        }
    }, [location]);

    if (!results) {
        return null;
    }
    return (
        <MasterPage>
            <h1>Search Results</h1>
            <StyledMasterContainer foreColor={themes.foreColor}>
                {results.map(product => {
                    return (
                        <ProductCard key={product.item_no} itemNo={product.item_no} handleClick={handleClick}
                            caption={product.caption} categoryDesc={product.category_desc} thumb={formatImageUrl(product.main_image, { sizeDesignator: ImageSizeEnum.full })}>
                        </ProductCard>
                    );
                })}
            </StyledMasterContainer>
        </MasterPage>
    );
}
interface ProductCardRecord extends PropsWithChildren {
    itemNo: string;
    thumb: string;
    caption: string;
    categoryDesc: string;
    handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const ProductCard: React.FC<ProductCardRecord> = (props) => {
    return (
        <StyledProductCard onClick={props.handleClick}>
            <StyledThumb id={props.itemNo} src={props.thumb} alt={props.caption}></StyledThumb>
            <StyledCaptionLine>{props.categoryDesc}</StyledCaptionLine>
            <StyledCaptionLine>{props.caption}</StyledCaptionLine>
        </StyledProductCard>
    );
}

export default Search;