import React from 'react';
import styled from 'styled-components';

import { useGlobalContext, useSessionStore, useTokens } from '../libs/SamState';
import IconButton from './IconButtonV2';
import addr from './api-shared/address-support';
import AddressEditor, { AddressSchemaType, renderAddressSummary } from '../libs/forms/AddressEditor';
import { StyledErrorText, getNearestParentId } from './libSupport';
import { usePostApi } from './useDataApiV2';
import SamModal from './SamModalV2';

import { AddressRecord, AddressType } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import api from '../api-url';
import FormMgr from './forms/FormMgr';

const useAddressBook = () => {
    const { getSessionStore, setSessionStore } = useSessionStore();
    const { post } = usePostApi();

    const setAddresses = (type: AddressType, addresses: AddressRecord[]) => {
        setSessionStore(type + "_addresses", addresses);
    }
    const getAddresses = (type: AddressType): AddressRecord[] => {
        return getSessionStore(type + "_addresses");
    }
    const isEmpty = (): boolean => {
        return !(getAddresses(AddressType.bill) || getAddresses(AddressType.ship));
    }
    // following used for dirty checks
    const getAddressesAsString = (type: AddressType): string => {
        const addresses = getAddresses(type);
        let result = '';
        addresses.forEach(address => {
            for (const property in address) {
                result += address[property as keyof AddressRecord];
            }
        });
        return result;
    }
    // return null if not found
    const getAddress = (type: AddressType, addressId: number): AddressRecord | undefined => {
        const addresses = getAddresses(type);
        if (!addresses) {
            return undefined;
        }
        return addresses.find(address => address.address_id + '' === addressId + '');
    }
    const getDefaultAddress = (type: AddressType): AddressRecord | undefined => {
        const addresses = getAddresses(type);
        if (!addresses) {
            return undefined;
        }
        const address = addresses.find(addr => addr.is_default);
        return address ? address : addresses[0];
    }
    const setAddress = (type: AddressType, address: AddressRecord, addressId: number) => {
        const isDefault = address.is_default;
        const addresses = getAddresses(type);
        for (let i = 0; i < addresses.length; i++) {
            if (isDefault) {
                addresses[i].is_default = false;
            }
            if (addresses[i].address_id === addressId) {
                addresses[i] = address;
            }
        }
        setAddresses(type, addresses);
    }
    const removeAddress = (type: AddressType, addressId: number) => {
        const addresses = getAddresses(type).filter(address => address.address_id !== addressId);
        setAddresses(type, addresses);
    }
    // return updated address book for posting
    const addNewAddress = (type: AddressType, address: AddressRecord): AddressRecord[] => {
        const addresses = getAddresses(type);
        if (address.is_default) {
            addresses.forEach(addr => {
                addr.is_default = false;
            });
        }
        const nextId = addresses.reduce((a, address) => Math.max(a, address.address_id), 0) + 1;
        address.address_id = nextId;
        addresses.push(address);
        setAddresses(type, addresses);
        return addresses;
    }
    const saveAddressBook = (recordType: AddressType, token: string, successCallback: (() => void) | null, failCallback: (() => void) | null) => {
        post(api.saveAddressBook, { addresses: getAddresses(recordType), record_type: recordType }, 
                successCallback ? successCallback : () => {}, failCallback ? failCallback : () => {}, token);
                // post(api.saveAddressBook, { addresses: "error", record_type: recordType }, 
                // successCallback ? successCallback : () => {}, failCallback ? failCallback : () => {}, token);
    }
   const includes = (type: AddressType, address: AddressRecord): boolean => {
        const addresses = getAddresses(type);
        if (!addresses) {
            return false;
        }
        const asString = addr.toSingleString(address);
        const found = addresses.find(address => addr.toSingleString(address) === asString);
        return !!found;
    }
    return {
        getAddress,
        getAddresses,
        isEmpty,
        getDefaultAddress,
        getAddressesAsString,
        setAddresses,
        setAddress,
        addNewAddress,
        removeAddress,
        saveAddressBook,
        includes
    }
}
//-------------------------------------------------------------------
const StyledMasterContainer = styled.div<{ backColor: string }>`
    width: ${window.innerWidth - 10}px;
    max-width: 900px;
    background-color: ${props => props.backColor};
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    h2 {
        margin-top: 16px;
        margin-bottom: 8px;
        font-size: 24px;
        text-align: center;
    }
    h3 {
        font-size: 22px;
    }
`
const AddressesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`
const ButtonsRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: 12px;
    @media screen and (max-width: 450px) {
        height: 50px;
    }

`
const addressBoxWidth = 230;
const addressBoxMargin = 4;     // on left and right

export interface AddressBookEditorProps {
    type: AddressType;
    schemaType: AddressSchemaType;
    showAsModal?: boolean;
    showTitle?: boolean;
    showUseButton?: boolean;     // true to show "Use as xxx address" in each box; if true the onSubmit arg is 0
    onSubmit: (addressId: number) => void;      // pass -1 to cancel
}
export const AddressBookEditor: React.FC<AddressBookEditorProps> = (props) => {
    if (props.showAsModal) {
        return (
            <SamModal component={Editor as React.FC} componentProps={props} />
        )
    } else {
        return (
            <Editor {...props} />
        )
    }
}
const Editor: React.FC<AddressBookEditorProps> = (props) => {
    const { getToken } = useTokens();
    const [editingAddressId, setEditingAddressId] = React.useState<number>(-1);     // -1 for hide, -2 for editing new, >=0 for editing existing
    const [originalBook, setOriginalBook] = React.useState<string>('');
    const [selectedId, setSelectedId] = React.useState<number>(-2);     // pass this back to checkout; if >=0 an address has been selected
    const [saveIfDirty, setSaveIfDirty] = React.useState<boolean>(false);
    const [errorMsg, setErrorMsg] = React.useState<string>('');

    // fgSaveAddressBook or saveAddressBook
   // add to api-url for ws: const saveAddresses = "api/" + (props.schemaType === AddressSchemaType.retail ? "fgS" : "s") + "aveAddressBook";

    const book = useAddressBook();
    const { setContext } = useGlobalContext();
    const forms = new FormMgr(setContext);

    const postAddressesSuccess = () => {
        props.onSubmit(selectedId);
    }
    const postAddressesFail = () => {
        setErrorMsg("We were unable to save your changes to our server");
    }
    React.useEffect(() => {
        //     console.log("saving original as " + book.getAddressesAsString(props.type))
        setOriginalBook(book.getAddressesAsString(props.type));
    }, []);
    React.useEffect(() => {
        if (saveIfDirty) {
            setSaveIfDirty(false);
            const addressesAsString = book.getAddressesAsString(props.type);
            if (addressesAsString !== originalBook) {
                // post success handles exit
                book.saveAddressBook(props.type, getToken()!.token, postAddressesSuccess, postAddressesFail);
            } else {
                props.onSubmit(selectedId);     // no changes made, just a selection
            }
        }
    }, [saveIfDirty]);
    const addresses = book.getAddresses(props.type);

    // click on a button under one of the address summaries
    const addressBoxClicked = (action: string, addressId: number) => {
        if (action === "edit") {
            setEditingAddressId(addressId);
        } else if (action === "remove") {
            book.removeAddress(props.type, addressId);
        } else if (action === "use") {
            setSelectedId(addressId);       // return this after post (if any)
            setSaveIfDirty(true);           // just in case an address was changed
        }
        //    updateValues(loadAddress(props.shipTos[e.target.id]));
    }
    // click on a button after choosing an address to edit
    const addressEditorClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const id = target.id;
        let addressId;
        if (id.startsWith("save")) {
            if (editingAddressId === -2) {
                // we're adding a new address
                const newBook = book.addNewAddress(props.type, forms.getFormValues(props.type + editingAddressId) as AddressRecord);
                addressId = newBook[newBook.length - 1].address_id;
            } else {
                const address = forms.getFormValues(props.type + editingAddressId) as AddressRecord;
                book.setAddress(props.type, address, editingAddressId);
                addressId = editingAddressId;
            }
            if (id === "saveUse") {
                setSelectedId(addressId);       // gets returned after post
                setSaveIfDirty(true);           // triggers the post
            }
        }
        setEditingAddressId(-1);            // closes the address editor
    }
    // click on one of the buttons below the main title
    const handleAddAddress = (e: React.MouseEvent<HTMLButtonElement>) => {
        setEditingAddressId(-2);
    }
    const handleSaveAndExit = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSelectedId(-1);      // trigger a save without selecting an address
        setSaveIfDirty(true);
    }
    const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        props.onSubmit(-1);
    }
    const buttonStyle = { margin: "8px", height: "80%" };
    const excludedFields = ["email", "contact_name"];
    if (props.schemaType === AddressSchemaType.retail) {
        excludedFields.push("res_del");
        excludedFields.push("storefront");
    }
    return (
        <StyledMasterContainer backColor={app.themes.backColor10 ?? ''}>
            {props.showTitle && <h2>Manage {props.type === AddressType.ship ? "Shipping" : "Billing"} Addresses</h2>}
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            {editingAddressId >= 0 || editingAddressId === -2 ? (
                <React.Fragment>
                    <h3>{(editingAddressId === -2 ? "Add a new" : "Edit") + " a " + (props.type === AddressType.ship ? "shipping" : "billing") + " address"}</h3>
                    <AddressEditor id={props.type + editingAddressId} addressType={props.type} 
                        values={editingAddressId === -2 ? null : book.getAddress(props.type, editingAddressId)}
                        schemaType={props.schemaType} excludedFields={excludedFields} />
                    <ButtonsRow>
                        {props.showUseButton && <IconButton id="saveUse" caption="Save and use this address" icon="fas fa-check" style={buttonStyle} onClick={addressEditorClicked} /> }
                        <IconButton id="save" caption="Save this address" icon="fas fa-save" style={buttonStyle} onClick={addressEditorClicked} />
                        <IconButton id="cancel" caption="Cancel changes" icon="fas fa-ban" style={buttonStyle} onClick={addressEditorClicked} />
                    </ButtonsRow>
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        <ButtonsRow>
                            <IconButton caption="Add new address" icon="fas fa-plus-circle" style={buttonStyle} onClick={handleAddAddress} />
                            <IconButton caption="Save changes" icon="fas fa-save" style={buttonStyle} onClick={handleSaveAndExit} />
                            <IconButton caption="Cancel changes" icon="fas fa-ban" style={buttonStyle} onClick={handleCancel} />
                        </ButtonsRow>
                        <AddressesContainer>
                            {addresses.map(((address) => {
                                return (
                                    <AddressBox key={address.address_id} address={address} showUseButton={props.showUseButton} width={addressBoxWidth} margin={addressBoxMargin} 
                                        handleClick={addressBoxClicked} />
                                )
                            }))}
                        </AddressesContainer>
                    </React.Fragment>
                )}
        </StyledMasterContainer>
    )
}

const StyledAddressBoxWithButtons = styled.div<{ margin: number; width: number }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: ${props => props.width}px;
    border: 1px solid;
    margin: 16px ${props => props.margin}px 4px ${props => props.margin}px;
`
const StyledAddressBox = styled.div`
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    p {
        margin: 2px;
    }
`
const StyledButtonsContainer = styled.div`
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`
const StyledButtonsRow = styled.div<{ marginTop?: number; marginBottom?: number }>`
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    display: flex;
    justify-content: center;
    width: 100%;
`

interface AddressBoxProps {
    address: AddressRecord;
    width: number;
    margin: number;
    showUseButton?: boolean;       // invalidates handleClick
    handleClick: (action: string, addressId: number) => void;      // "edit", "remove", "use"
}
const AddressBox: React.FC<AddressBoxProps> = (props) => {
    const handleAddressBoxClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const parts = target.id.split('-');
        props.handleClick(parts[0], parseInt(parts[1]));
    }
    return (
        <StyledAddressBoxWithButtons width={props.width} margin={props.margin}>
            <StyledAddressBox>
                {renderAddressSummary(props.address)}
            </StyledAddressBox>
            <StyledButtonsContainer>
                <StyledButtonsRow>
                    <IconButton id={"edit-" + props.address.address_id} caption="Edit" onClick={handleAddressBoxClick} icon="fas fa-edit" style={{ width: "78px", marginRight: "8px" }} />
                    <IconButton id={"remove-" + props.address.address_id} caption="Delete" onClick={handleAddressBoxClick} icon="fas fa-times-circle" style={{ width: "98px", marginLeft: "8px" }} />
                </StyledButtonsRow>
                {props.showUseButton &&
                    <StyledButtonsRow marginTop={8} marginBottom={4}>
                        <IconButton id={"use-" + props.address.address_id} caption="Use as shipping address" onClick={handleAddressBoxClick} icon="fas fa-check" />
                    </StyledButtonsRow>
                }
            </StyledButtonsContainer>
        </StyledAddressBoxWithButtons>
    );
}

//-------------------------------------------------------------------
const ChooserMain = styled.div`
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #666;
    position: relative;
`
const ChooserInitialInput = styled.div<{ backColor: string }>`
    height: 24px;
    display: flex;
    justify-content: space-between;
    border: 1px solid;
    background-color: ${props => props.backColor};
    padding: 8px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
`
const ChooserDropdown = styled.div<{ backColor: string }>`
    overflow: auto;
    height: 200px;
    width: 100%;
    position: absolute;
    z-index: 2000;
    background-color: ${props => props.backColor};
    border: 1px solid;
`
const ChooserList = styled.div`
    display: flex;
    flex-direction: column;
`
const ChooserItem = styled.div<{ id?: any }>`
    cursor: pointer;
    border-bottom: 1px solid #666;
    padding: 8px;
    :hover {
        color: blue;
    }
    p {
        text-align: left;
        font-size: 13px;
        line-height: 14px;
        margin-top: 0;
        margin-bottom: 0;
    }
`
export interface AddressChooserProps {
    type: AddressType;
    onSubmit: (addressId: number) => void;
}
export const AddressChooser: React.FC<AddressChooserProps> = (props) => {
    const [dropdownShown, setDropdownShown] = React.useState(false);
    const book = useAddressBook();

    const addressChosen = (e: React.MouseEvent<HTMLElement>) => {
        const id = getNearestParentId(e.target as HTMLElement).id;
        setDropdownShown(false);
        props.onSubmit(parseInt(id));
    }
    return (
        <ChooserMain>
            {dropdownShown ? (
                <ChooserDropdown backColor={app.themes.backColor10 ?? ''}>
                    <ChooserList>
                        <ChooserItem onClick={() => setDropdownShown(false)}><p>Use existing address</p></ChooserItem>
                        {book.getAddresses(props.type).map((address) => {
                            return (
                                <ChooserItem key={address.address_id} id={address.address_id} onClick={addressChosen}>
                                    {renderAddressSummary(address)}
                                </ChooserItem>
                            )
                        })}
                    </ChooserList>
                </ChooserDropdown>
            ) : (
                    <ChooserInitialInput backColor={app.themes.backColor10 ?? ''} onClick={() => setDropdownShown(true)}>
                        <span>Click to choose address</span>
                        <i className="fa fa-chevron-down" />
                    </ChooserInitialInput>
                )}
        </ChooserMain>
    )
}
export default useAddressBook;